import { useEffect, useState } from "react";
import { API_shortUserInfo } from "../../API/api";
import DropMenu from "../../components/DropMenu/DropMenu";
import useSVG from "../../data/icons/icons";
import "./profile-bar.css"

interface FormLineType {
    showAccessToken: string;
    setLoadingWorkspace: any;
    setModal: any;
}

const ProfileBar: React.FC<FormLineType> = ({ showAccessToken, setLoadingWorkspace, setModal }) => {

    const [showDropMenu, setDropMenu] = useState(false);
    const [showUserName, setUserName] = useState("");

    const { iconMenu } = useSVG();

    var goTo = function () {
        window.open("https://identity.emg.fm:5000/?returnUrl=https://doc-edo.emg.fm/", "_self");
    }

    const goOptions = () =>{
        setModal({ modalResume: true, modalID: 7, payload: {} })
    };

    const fields = [
        { value: 'Сменить пользователя', onClick: () => goTo() },
        { value: 'Настройки', onClick: () => goOptions() },
        { value: 'Выход', onClick: () => console.log("exit"), color: "color-red" }
    ]

    const getName = async () => {
        var responseShortUserInfo = await API_shortUserInfo(showAccessToken);
        if (responseShortUserInfo.status) {
            setUserName(responseShortUserInfo.data.Title);
        }
        setLoadingWorkspace(false);
    }

    useEffect(() => {
        if (showAccessToken)
            getName();
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAccessToken]);

    return (
        <div className="profile-bar" tabIndex={0} onBlur={() => setDropMenu(false)}>
                <div className="profile-bar-title">
                    <span className="profile-bar-title-name">{showUserName ? showUserName : null}</span>
                    <span className='menu-line' />
                    <span className='profile-bar-title-menu' onClick={() => setDropMenu(!showDropMenu)}>{iconMenu}</span>
                </div>
            <DropMenu options={fields} open={showDropMenu} />
        </div>
    )
}

export default ProfileBar;