interface FormLineType {
    showAllCheck: boolean;
    setAllCheck: any;
    setCheckIdList: any;
    validAllCheck: boolean;
    disabled?: boolean;
}

const TableHeaderCheckbox: React.FC<FormLineType> = ({ showAllCheck, setAllCheck, setCheckIdList, validAllCheck, disabled }) => {

    const onChangeCheck = (check: boolean) => {
        setAllCheck(check)
        if (check === false) {
            setCheckIdList([])
        }
    }

    return (
        <div className="table__header__checkbox">
            <input type="checkbox"
                className="checkbox"
                checked={validAllCheck}
                onChange={() => onChangeCheck(!showAllCheck)}
                readOnly
                disabled={disabled}
            />
        </div>
    )
}


export default TableHeaderCheckbox;