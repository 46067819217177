import LightTableHeaderCell from "./light-table-header-cell/LightTableHeaderCell";

interface FormLineType {
    headers: any;
    options: any;
}

const LightTableHeader: React.FC<FormLineType> = ({
    headers, options
}) => {
    return (
        <div className="light-table-header">
            {headers.map((header: any, key: any) => 
            <LightTableHeaderCell title={header.title} width={options[header.type]} key={key}/>
            )}
        </div>
    )
};

export default LightTableHeader;