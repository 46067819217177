import "./unloadModal.css"

import headers from "../../../data/json/modalUpload/realizationDocs/headers.json";
import options from "../../../data/json/modalUpload/realizationDocs/options.json";
import Button from '../../../components/Button/Button';
import Table from "../../../components/Table/Table";
import { useEffect, useState } from "react";
import Search from "../../../components/Search/Search";
import Modal from "../../../components/Modal/Modal";
import { IDispatchNotificationProps } from "../../../components/DispatchNotification/DispatchNotification";
import UploadStatus from "../../../components/UploadStatus/UploadStatus";

interface FormLineType {
    modal: any;
    setModal: any;
    setDispatchNotification: (value: IDispatchNotificationProps) => void;
}

const UnloadRealizationDocsModal: React.FC<FormLineType> = (
    {
        modal, setModal, setDispatchNotification,
    }
) => {

    const [showModalItemsCo, setModalItemsCo] = useState([]);
    const [showModalViewCo, setModalViewCo] = useState(showModalItemsCo);

    const [showLoadingUnloadModalDoc, setLoadingUnloadModalDoc] = useState(true);

    const RealizationDocs = () => {
        setLoadingUnloadModalDoc(true);

        var realizationDocs = modal.payload.data;
        const generateStatus = (status: any) => {
            return status.GenerateDocsStatus === "Success" ? <UploadStatus statusStyle="success">Выгружен</UploadStatus> : <UploadStatus statusStyle="error" title={status.Message}>Ошибка </UploadStatus>;
        }
        
        if (realizationDocs.length > 0) {
            setModalItemsCo(
                realizationDocs.map((realizations: any) => ({
                    id: realizations.EDxModel.ID,
                    "doc_num": realizations.EDxModel.Number ? realizations.EDxModel.Number : "",
                    "posting_date": realizations.EDxModel.PostingDate ? realizations.EDxModel.PostingDate : "",
                    "co_name": realizations.EDxModel.Contractor.FullName ? realizations.EDxModel.Contractor.FullName : "",
                    "generate_status": realizations.GenerateDocsStatus ? generateStatus(realizations) : "",
                }))
            );
        }
        else {
            setDispatchNotification({ show: true, type: "error", title: "Выгрузка договоров не загрузились", context: "Произошла ошибка, выгрузка договоров не загрузились, обратитесь в тех поддержку" })
            setTimeout(() => {
                setDispatchNotification({ show: false, type: "error", title: "", context: "" })
            }, 5000);
        }

        if (showModalViewCo)
            setLoadingUnloadModalDoc(false);
    }

    function sum(obj: any) {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    var onButtonHandler = (event: any) => {
        if (event.key === 'Escape') {
            setModal({ modalResume: false, modalID: 0, payload: {} });
        }
    }

    useEffect(() => {
        document.addEventListener<'keydown'>('keydown', onButtonHandler, false);

        return () => {
            document.removeEventListener<'keydown'>('keydown', onButtonHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setModalViewCo(showModalItemsCo);
    }, [showModalItemsCo]);

    useEffect(() => {
        RealizationDocs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal modal={modal} >
            <div className='unloadModal' style={{ width: sum(options) + 15 + 10 }} >
                <div className="modal-title">Выгрузка реализации</div>
                <div className="modal-co-search" >
                    <Search items={showModalItemsCo} setItems={setModalViewCo} />
                </div>
                <Table
                    setListItems={setModalItemsCo} showListItems={showModalViewCo}
                    headers={headers} options={options} showLoading={showLoadingUnloadModalDoc}
                />
                <div className="unloadModal-button">
                    <Button text="Назад" onClick={() => setModal({ ...modal, modalResume: false, modalID: 0, payload: {} })} />
                </div>
            </div>
        </Modal>
    );
}

export default UnloadRealizationDocsModal;