import { useEffect } from "react";
import "./modal.css";

interface FormLineType {
    modal: any;
    children: any;
}

const Modal: React.FC<FormLineType> = ({
    modal, children
}) => {

    return (
        <div className={`modal-wrapper ${modal.modalResume ? 'open' : 'close'}`} >
            <div className='modal-body'>
                {children}
            </div>
        </div>
    )
};

export default Modal;