import useSVG from "../../data/icons/icons";
import "./uploadStatus.css"

interface FormLineType {
    statusStyle: "success" | "error";
    children: any;
    title?: string;
}

const UploadStatus: React.FC<FormLineType> = ({ children, statusStyle, title }) => {

    const uploadStatusStyle: string = "uploadStatus-" + statusStyle;
    const { iconInfo } = useSVG();
    return (
        <div className={["uploadStatus", uploadStatusStyle].join(' ')}>
            <div className={'uploadStatus-text'}>{children} {statusStyle === "error" && <span title={title}>{iconInfo}</span>}</div>
        </div>
    )
}

export default UploadStatus;