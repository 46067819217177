import Button from "../Button/Button";
import "./dispatch-notification.css";

export interface IDispatchNotificationProps {
    show: boolean, type: "success" | "error", title: string, context: string, buttonText?: string, buttonClick?: () => void;
}

const DispatchNotification: React.FC<IDispatchNotificationProps> = ({ show, type, title, context, buttonText, buttonClick }) => {

    return (
        <div className={`dispatch-notification ${type} ${show ? 'open' : 'close'}`}>
            {show && <div className="dispatch-notification-content">
                <div className="dispatch-notification-content-title">{title}</div>
                <div className="dispatch-notification-content-context">{context}</div>
                <div className="dispatch-notification-content-button">
                    <Button text={buttonText || "Это не я"} classStyle={"purple-outline"} onClick={buttonClick} />
                </div>
            </div>}
        </div>
    )
};

export default DispatchNotification;