import { useState } from "react";
import TableHeaderCell from "./table-header-cell/table-header-cell";
import TableHeaderCheckbox from "./table-header-checkbox/table-header-checkbox";
import TableHeaderFunction from "./table-header-function/table-header-function";

interface FormLineType {
    sorting: any;
    headers: any;
    options: any;
    onCheckbox: boolean;
    onFuction: boolean;
    showAllCheck: boolean;
    setAllCheck: any;
    setCheckIdList: any;
    validAllCheck: boolean;
    disabledAllCheck?: boolean;
}

const TableHeader: React.FC<FormLineType> = ({
    sorting, headers, options, onCheckbox, onFuction, showAllCheck, setAllCheck, setCheckIdList, validAllCheck, disabledAllCheck
}) => {

    const [showTypeSort, setTypeSort] = useState("");
    return (
        <div className="table__row__header">
            {onCheckbox && <TableHeaderCheckbox
                showAllCheck={showAllCheck} setAllCheck={setAllCheck} setCheckIdList={setCheckIdList} validAllCheck={validAllCheck} disabled={disabledAllCheck}
            />}
            {headers.map((header: any, key: any) =>
                <TableHeaderCell
                    item={header.title} sorting={sorting}
                    keyItem={header.type} order={header.order}
                    key={key} width={options[header.type]}
                    showTypeSort={showTypeSort} setTypeSort={setTypeSort}
                />
            )}
            {onFuction && <TableHeaderFunction />}
        </div>
    )
};

export default TableHeader;