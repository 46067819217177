import Checkbox from "../../../Checkbox/Checkbox";

interface FormLineType {
  index: number;
  id: number;
  onCheck: boolean;
  disabled?: boolean;
}

const TableCellCheckbox: React.FC<FormLineType> = ({
  index, id, onCheck, disabled
}) => {

  return (
    <div className="table__cell__checkbox">
        <Checkbox
          index={index}
          id={id}
          onCheck={onCheck}
          disabled={disabled}
          height={20}
        />
    </div>
  )
}


export default TableCellCheckbox;