interface FormLineType {
    title: string;
    width: any;
}

const LightTableTotalCell: React.FC<FormLineType> = ({
    title, width
}) => {
    return (
        <div className="light-table-header-cell" style={{ width: width }}>
            { title }
        </div >
    )
};

export default LightTableTotalCell;