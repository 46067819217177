import Button from "../../components/Button/Button";
import Search from "../../components/Search/Search";
import "./header.css"

interface FormLineType {
    modal: any;
    setModal: any;
    showLoadingCo: boolean;
    showItemsCo: any;
    setViewContragents: any;
}

const HeaderCo: React.FC<FormLineType> = ({modal, setModal, showLoadingCo, showItemsCo, setViewContragents }) => {
    
    return (
        <div className="header">
            <Search items={showItemsCo} setItems={setViewContragents} fillStyle={true}/>
            <Button text={"Добавить контрагента"} classStyle={"purple-outline"}  onClick={() => setModal({...modal, modalResume: true, modalID: 1})} disabled={showLoadingCo}/>
        </div>
    )
}

export default HeaderCo;