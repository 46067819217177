import { useState } from "react";
import useSVG from "../../../../data/icons/icons";

interface FormLineType {

}

const TableHeaderFunction: React.FC<FormLineType> = () => {

  const { iconSliders } = useSVG();

  return (
    <div className="table__header__function">
      {iconSliders}
    </div>
  )
}


export default TableHeaderFunction;