import { useEffect, useState } from "react";
import "./subtab.css";

interface FormLineType {
    title: string;
    showSubTab: number;
    setSubTab: any;
    id: number;
    disabled: boolean;
    noLine?: boolean;
}

const Subtab: React.FC<FormLineType> = ({
    title, setSubTab, showSubTab, id, disabled, noLine
}) => {

    const tabSelected: string = (id === showSubTab && !disabled) ? noLine ? "subtab-selected-noline" : "subtab-selected" : '';

    useEffect(() => {
        localStorage.setItem('subtab', showSubTab.toString());
    }, [showSubTab])

    return (
        <div className={['subtab', tabSelected].join(' ')} onClick={() => setSubTab(id)} >
            {title}
        </div>
    )
};

export default Subtab;