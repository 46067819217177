import Logo from "../../components/Logo/Logo";
import CompanyList from "../companyList/CompanyList";
import ProfileBar from "../ProfileBar/ProfileBar";
import Tabs from "../tabs/Tabs";
import "./top-menu.css"
import Button from "../../components/Button/Button";
import React from "react";

interface FormLineType {
    showAccessToken: string;
    showTab: number;
    setTab: any;
    showCompanyID: number;
    setCompanyID: any;
    setLoadingWorkspace: any;
    disabledTabs: boolean;
    setNoAccess: any;
    showSubTab: number;
    setSubTab: any;
    setModal: any;
}

const TopMenu: React.FC<FormLineType> = ({ showAccessToken, showTab, setTab, showCompanyID, setCompanyID, setLoadingWorkspace, disabledTabs, setNoAccess, showSubTab, setSubTab, setModal }) => {
    /*const openUserGuide = (e: React.MouseEvent<HTMLButtonElement>)=>{
        return(
        )
    }*/
    return (
        <div className="top-menu">
            <div className="top-menu-info-bar">
                <div className="top-menu-info-bar-company">
                    <Logo />
                    {showAccessToken && <CompanyList showAccessToken={showAccessToken} showCompanyID={showCompanyID} setCompanyID={setCompanyID} setNoAccess={setNoAccess} />}
                    <a target="_blank" href="https://sft-minio.europaplus.ru/edxdocs/%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F_%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%BE%D0%B9_%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE_%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%BE%D0%BE%D0%B1%D0%BE%D1%80%D0%BE%D1%82%D0%B0.pdf">
                        <Button classStyle={"grey-outline"} text={"Как пользоваться?"} onClick={{/*openUserGuide*/}}></Button>
                    </a>
                </div>
                {showAccessToken && <ProfileBar showAccessToken={showAccessToken} setLoadingWorkspace={setLoadingWorkspace} setModal={setModal}/>}
            </div>
            <Tabs showTab={showTab} setTab={setTab} showSubTab={showSubTab} setSubTab={setSubTab} disabled={disabledTabs} />
        </div>
    )
}

export default TopMenu;