import TableRowTotalCell from "./table-row-total-cell/table-row-total-cell";

interface FormLineType {
    showListItems: any;
    showCheckIdList: any;
    options: any;
    headers: any;
}

const TableRowTotal: React.FC<FormLineType> = ({ showListItems, showCheckIdList, options, headers }) => {
    return (
        <div className="table__row__total">
            <div className="table__row__total__length">{showCheckIdList.length} из {showListItems.length}</div>
            {headers.map((header: any, key: any) => <TableRowTotalCell headerType={header.type} options={options} showCheckIdList={showCheckIdList} showListItems={showListItems} key={key} />)}
        </div>
    )
};

export default TableRowTotal;