import Button from "../../components/Button/Button";
import Search from "../../components/Search/Search";
import SpanDate from "../../components/SpanDate/SpanDate";
import "./header.css"

interface FormLineType {
    showStartDate: Date;
    setStartDate: any;
    showEndDate: Date;
    setEndDate: any;
    generateRealizationDocs: any;
    showLoadingRealizationDocs: boolean;
    updateRealizationDocs: any;
    showCheckRealizationDocsLength: number;
    showItemsRealizationDocs: any;
    setViewRealizationDocs: any;
}

const HeaderRealizationDocs: React.FC<FormLineType> = ({ showStartDate, setStartDate, showEndDate, setEndDate, generateRealizationDocs, showLoadingRealizationDocs, updateRealizationDocs, showCheckRealizationDocsLength,
    showItemsRealizationDocs, setViewRealizationDocs
}) => {

    return (
        <div className="header">
            <div className="header-input">
                <SpanDate showStartDate={showStartDate} setStartDate={setStartDate} showEndDate={showEndDate} setEndDate={setEndDate} disabled={showLoadingRealizationDocs}/>
                <Search items={showItemsRealizationDocs} setItems={setViewRealizationDocs} fillStyle={true} disabled={showLoadingRealizationDocs}/>
            </div>
            <div className="header-buttons">
                <Button text={"Обновить"} classStyle={"purple-outline"} onClick={() => updateRealizationDocs()} disabled={showLoadingRealizationDocs} />
                <Button text={"Выгрузить"} classStyle={"purple-fill"} onClick={() => generateRealizationDocs()} disabled={showLoadingRealizationDocs || showCheckRealizationDocsLength === 0} />
            </div>
        </div>
    )
}

export default HeaderRealizationDocs;