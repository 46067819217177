import "./icons.css";

const useSVG = () => {

    let iconRole = <svg
        width={22}
        height={22}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.7181 12.3707C13.0071 11.2252 11.4454 10 8.25001 10C5.05464 10 3.49293 11.2252 2.78186 12.3707C4.06463 13.9735 6.03744 15 8.25001 15C10.4626 15 12.4354 13.9735 13.7181 12.3707Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.25 9C9.90685 9 11.25 7.65685 11.25 6C11.25 4.34315 9.90685 3 8.25 3C6.59315 3 5.25 4.34315 5.25 6C5.25 7.65685 6.59315 9 8.25 9Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.25 1C4.38401 1 1.25 4.13401 1.25 8C1.25 11.866 4.38401 15 8.25 15C12.116 15 15.25 11.866 15.25 8C15.25 4.13401 12.116 1 8.25 1ZM0.25 8C0.25 3.58172 3.83172 0 8.25 0C12.6683 0 16.25 3.58172 16.25 8C16.25 12.4183 12.6683 16 8.25 16C3.83172 16 0.25 12.4183 0.25 8Z"
            fill="black"
        />
    </svg>

    let iconPath = <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L7 6.29289L12.6464 0.646447C12.8417 0.451184 13.1583 0.451184 13.3536 0.646447C13.5488 0.841709 13.5488 1.15829 13.3536 1.35355L7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z" fill="black" />
    </svg>

    let iconMenu = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 18H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 12H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 6H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

    let iconSliders = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 21V16" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 16H23" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 21V14" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 14H7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 21V12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 8H15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20 12V3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 8V3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 10V3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

    let iconVertical = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7Z" fill="#718096" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="#718096" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21Z" fill="#718096" />
    </svg>

    let iconInfo = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="white" />
        <path d="M8.9307 6.58789L6.63969 6.875L6.55766 7.25586L7.00883 7.33789C7.3018 7.4082 7.36039 7.51367 7.29594 7.80664L6.55766 11.2754C6.3643 12.1719 6.66312 12.5938 7.36625 12.5938C7.91117 12.5938 8.54398 12.3418 8.83109 11.9961L8.91898 11.5801C8.71977 11.7559 8.4268 11.8262 8.23344 11.8262C7.95805 11.8262 7.85844 11.6328 7.92875 11.293L8.9307 6.58789Z" fill="white" />
        <circle cx="8" cy="4.5" r="1" fill="white" />
    </svg>


    let noAccess = <svg width="353" height="275" viewBox="0 0 353 275" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1510_8897)">
            <path d="M70.3329 275C109.177 275 140.666 272.322 140.666 269.019C140.666 265.716 109.177 263.038 70.3329 263.038C31.4891 263.038 0 265.716 0 269.019C0 272.322 31.4891 275 70.3329 275Z" fill="#E6E6E6" />
            <path d="M53.7612 61.0177C66.0013 55.1884 81.2465 58.8817 96.135 61.4451C96.2735 58.3409 97.9529 54.5629 96.1937 52.3972C94.0565 49.7661 94.2533 47.0015 95.5394 44.221C98.828 37.1109 94.1163 29.5454 89.3588 23.2547C88.2866 21.84 86.9314 20.7285 85.4034 20.0103C83.8754 19.2922 82.2176 18.9878 80.5648 19.1218L71.7521 19.8266C69.6076 19.9981 67.561 20.8989 65.8862 22.4084C64.2114 23.9178 62.9883 25.964 62.3805 28.2731C60.2761 31.4776 59.1499 34.6732 59.859 37.8522C56.7071 40.2641 56.1775 43.1754 57.5939 46.4573C58.7949 48.5238 58.7821 50.5353 57.5392 52.4911C55.907 54.9847 54.6568 57.7651 53.84 60.7183L53.7612 61.0177Z" fill="#2F2E41" />
            <path d="M344.635 237.733H95.168C92.9495 237.733 90.8219 236.746 89.2532 234.99C87.6845 233.233 86.8032 230.851 86.8032 228.367C177.235 216.599 265.967 216.599 353 228.367C353 230.851 352.119 233.233 350.55 234.99C348.981 236.746 346.854 237.733 344.635 237.733Z" fill="#2F2E41" />
            <path d="M353 228.918L86.8032 228.367L117.654 170.242L117.802 169.967V41.8664C117.802 40.3115 118.075 38.7718 118.607 37.3353C119.138 35.8988 119.917 34.5935 120.899 33.4941C121.881 32.3946 123.047 31.5225 124.329 30.9275C125.612 30.3326 126.988 30.0264 128.376 30.0266H309.951C311.339 30.0264 312.715 30.3326 313.997 30.9275C315.28 31.5225 316.446 32.3946 317.428 33.4941C318.41 34.5935 319.189 35.8988 319.72 37.3353C320.252 38.7718 320.525 40.3115 320.525 41.8664V170.848L353 228.918Z" fill="#3F3D56" />
            <path d="M128.135 38.2907C127.222 38.2919 126.347 38.6986 125.701 39.4216C125.055 40.1446 124.692 41.1249 124.691 42.1473V158.948C124.692 159.97 125.055 160.951 125.701 161.674C126.347 162.397 127.222 162.803 128.135 162.804H311.668C312.581 162.803 313.457 162.397 314.103 161.674C314.748 160.951 315.111 159.97 315.113 158.948V42.1473C315.112 41.1249 314.748 40.1446 314.103 39.4216C313.457 38.6986 312.581 38.2919 311.668 38.2907H128.135Z" fill="white" />
            <path d="M128.904 178.231C128.622 178.231 128.346 178.322 128.108 178.493C127.871 178.663 127.682 178.906 127.563 179.193L118.057 202.333C117.954 202.584 117.908 202.861 117.925 203.138C117.943 203.414 118.022 203.682 118.155 203.915C118.289 204.149 118.472 204.341 118.69 204.474C118.907 204.607 119.15 204.676 119.398 204.676H319.309C319.561 204.676 319.808 204.604 320.028 204.467C320.248 204.33 320.432 204.132 320.565 203.892C320.697 203.653 320.772 203.379 320.783 203.098C320.795 202.816 320.742 202.536 320.629 202.284L310.296 179.145C310.174 178.869 309.986 178.638 309.753 178.477C309.519 178.315 309.25 178.23 308.976 178.231H128.904Z" fill="#2F2E41" />
            <path d="M219.163 36.3624C220.386 36.3624 221.378 35.2524 221.378 33.8832C221.378 32.5139 220.386 31.4039 219.163 31.4039C217.941 31.4039 216.949 32.5139 216.949 33.8832C216.949 35.2524 217.941 36.3624 219.163 36.3624Z" fill="white" />
            <path d="M199.514 209.084C199.189 209.084 198.874 209.204 198.617 209.425C198.359 209.646 198.173 209.956 198.089 210.307L195.704 220.224C195.645 220.469 195.637 220.726 195.681 220.975C195.725 221.223 195.82 221.458 195.958 221.659C196.096 221.86 196.273 222.024 196.476 222.136C196.679 222.248 196.903 222.307 197.129 222.307H242.478C242.713 222.307 242.945 222.244 243.154 222.123C243.364 222.002 243.544 221.827 243.68 221.612C243.817 221.398 243.906 221.15 243.939 220.889C243.972 220.628 243.95 220.362 243.873 220.113L240.807 210.196C240.706 209.871 240.518 209.59 240.268 209.39C240.018 209.191 239.719 209.084 239.412 209.084H199.514Z" fill="#2F2E41" />
            <path d="M320.525 168.038V170.242H117.654L117.807 169.967V168.038H320.525Z" fill="#2F2E41" />
            <path d="M314.5 67C333.554 67 349 52.0015 349 33.5C349 14.9985 333.554 0 314.5 0C295.446 0 280 14.9985 280 33.5C280 52.0015 295.446 67 314.5 67Z" fill="#6C63FF" />
            <path d="M329.061 48H300.939C300.425 47.9995 299.932 47.8033 299.569 47.4545C299.205 47.1057 299.001 46.6328 299 46.1395V29.8605C299.001 29.3672 299.205 28.8943 299.569 28.5455C299.932 28.1967 300.425 28.0005 300.939 28H329.061C329.575 28.0005 330.068 28.1967 330.431 28.5455C330.795 28.8943 330.999 29.3672 331 29.8605V46.1395C330.999 46.6328 330.795 47.1057 330.431 47.4545C330.068 47.8033 329.575 47.9995 329.061 48ZM300.939 29.8605V46.1395H329.062L329.061 29.8605L300.939 29.8605Z" fill="white" />
            <path d="M324 29H306V21.4444C306 15.9719 309.785 12 315 12C320.215 12 324 15.9719 324 21.4444L324 29ZM308 27.1111H322V21.4444C322 16.9959 319.122 13.8889 315 13.8889C310.878 13.8889 308 16.9959 308 21.4444L308 27.1111Z" fill="white" />
            <path d="M314.867 45.1776C315.954 45.1776 316.835 44.1909 316.835 42.9738C316.835 41.7567 315.954 40.77 314.867 40.77C313.78 40.77 312.898 41.7567 312.898 42.9738C312.898 44.1909 313.78 45.1776 314.867 45.1776Z" fill="white" />
            <path d="M290.822 123.187H148.981C148.285 123.186 147.617 122.876 147.125 122.324C146.632 121.773 146.355 121.026 146.354 120.246V80.8494C146.355 80.0696 146.632 79.3221 147.125 78.7707C147.617 78.2193 148.285 77.9092 148.981 77.9082H290.822C291.519 77.9092 292.186 78.2193 292.679 78.7707C293.171 79.3221 293.448 80.0696 293.449 80.8494V120.246C293.448 121.026 293.171 121.773 292.679 122.324C292.186 122.876 291.519 123.186 290.822 123.187ZM148.981 79.0847C148.563 79.0852 148.163 79.2713 147.867 79.6021C147.572 79.933 147.406 80.3815 147.405 80.8494V120.246C147.406 120.714 147.572 121.162 147.867 121.493C148.163 121.824 148.563 122.01 148.981 122.01H290.822C291.24 122.01 291.641 121.824 291.936 121.493C292.231 121.162 292.398 120.714 292.398 120.246V80.8494C292.398 80.3815 292.231 79.9329 291.936 79.6021C291.641 79.2713 291.24 79.0852 290.822 79.0847L148.981 79.0847Z" fill="#E6E6E6" />
            <path d="M171.021 111.016C176.93 111.016 181.72 105.653 181.72 99.0359C181.72 92.4193 176.93 87.0554 171.021 87.0554C165.111 87.0554 160.321 92.4193 160.321 99.0359C160.321 105.653 165.111 111.016 171.021 111.016Z" fill="#E6E6E6" />
            <path d="M195.731 91.049C195.497 91.0485 195.265 91.0999 195.048 91.2C194.831 91.3002 194.635 91.4472 194.469 91.6327C194.303 91.8181 194.171 92.0384 194.081 92.2809C193.992 92.5233 193.945 92.7832 193.945 93.0457C193.945 93.3082 193.992 93.5681 194.081 93.8106C194.171 94.053 194.303 94.2733 194.469 94.4587C194.635 94.6442 194.831 94.7912 195.048 94.8914C195.265 94.9915 195.497 95.0429 195.731 95.0425H279.8C280.273 95.0425 280.727 94.8321 281.061 94.4576C281.396 94.0831 281.584 93.5753 281.584 93.0457C281.584 92.5161 281.396 92.0082 281.061 91.6338C280.727 91.2593 280.273 91.049 279.8 91.049H195.731Z" fill="#E6E6E6" />
            <path d="M195.732 103.029C195.259 103.029 194.805 103.24 194.471 103.614C194.136 103.989 193.948 104.497 193.948 105.026C193.948 105.556 194.136 106.064 194.471 106.438C194.805 106.813 195.259 107.023 195.732 107.023H231.907C232.38 107.023 232.833 106.813 233.168 106.438C233.502 106.064 233.69 105.556 233.69 105.026C233.69 104.497 233.502 103.989 233.168 103.614C232.833 103.24 232.38 103.029 231.907 103.029H195.732Z" fill="#E6E6E6" />
            <path d="M117.306 103.837L91.0853 119.446L90.7534 106.439C99.3116 104.839 107.454 102.054 114.982 97.5194L117.725 89.9333C118.024 89.1066 118.509 88.3812 119.134 87.8282C119.759 87.2753 120.501 86.9137 121.289 86.7791C122.076 86.6445 122.881 86.7414 123.625 87.0604C124.369 87.3794 125.025 87.9094 125.531 88.5985C126.271 89.6068 126.64 90.8921 126.565 92.1941C126.49 93.4961 125.976 94.7176 125.127 95.6109L117.306 103.837Z" fill="#FFB8B8" />
            <path d="M53.2386 193.956C53.0473 193.17 53.0067 192.348 53.1194 191.544C53.2321 190.74 53.4957 189.971 53.8926 189.289L59.6525 179.391C61.0148 177.05 63.1053 175.359 65.5181 174.646C67.931 173.934 70.4944 174.25 72.7108 175.534C70.2901 180.147 70.6276 184.195 73.5468 187.712C69.015 190.189 64.8266 193.387 61.1112 197.205C60.433 197.723 59.654 198.051 58.8393 198.164C58.0246 198.276 57.1977 198.169 56.4274 197.851C55.6572 197.533 54.966 197.014 54.4113 196.336C53.8566 195.659 53.4545 194.843 53.2386 193.956Z" fill="#2F2E41" />
            <path d="M118.5 181.014C117.628 182.641 116.398 183.989 114.927 184.93C113.456 185.871 111.794 186.373 110.1 186.387L71.8348 186.712L70.1753 175.934L87.1024 170.36L72.8305 158.096L88.43 137.656L116.763 167.225C118.419 168.953 119.495 171.266 119.812 173.776C120.128 176.285 119.665 178.841 118.5 181.014Z" fill="#2F2E41" />
            <path d="M65.8602 254.721H56.8988C48.8528 192.966 40.6627 131.039 64.8645 107.925L93.4082 113.128L89.7573 140.257L73.8259 160.697L65.8602 254.721Z" fill="#2F2E41" />
            <path d="M73.7378 268.71C73.0363 268.924 72.3022 268.97 71.5838 268.843C70.8655 268.717 70.1791 268.422 69.5698 267.978L59.8396 266.513C57.7488 264.988 56.2385 262.648 55.6017 259.946C54.965 257.244 55.2473 254.374 56.3937 251.892C60.5144 254.602 64.1291 254.224 67.2702 250.955C69.4897 255.973 73.1465 255.667 76.639 259.895C77.1014 260.654 77.3951 261.526 77.4955 262.438C77.596 263.351 77.5003 264.277 77.2165 265.139C76.9326 266.002 76.4688 266.776 75.8637 267.397C75.2586 268.018 74.5298 268.468 73.7378 268.71Z" fill="#2F2E41" />
            <path d="M87.102 66.6738L70.5068 62.2142C73.263 55.8971 73.49 48.8681 72.1663 41.4026L83.4511 41.031C83.8047 50.3145 84.8744 58.9876 87.102 66.6738Z" fill="#FFB8B8" />
            <path d="M91.663 124.318C79.5824 133.535 71.0465 124.633 64.5327 110.898C65.4378 102.494 63.9722 92.4361 61.2578 81.5076C60.0507 76.6874 60.5014 71.5355 62.5209 67.0692C64.5405 62.6029 67.9821 59.1467 72.1664 57.3829L86.4383 64.0724C98.5568 75.1327 100.942 87.1133 96.3954 99.7492L91.663 124.318Z" fill="#6C63FF" />
            <path d="M56.8989 75.9646L43.6228 83.7689L67.1879 99.3776L70.4669 108.434C70.6857 109.038 70.7923 109.686 70.7802 110.338C70.7681 110.99 70.6375 111.633 70.3964 112.227C70.1552 112.82 69.8086 113.353 69.3777 113.792C68.9468 114.231 68.4405 114.567 67.8898 114.779C67.2638 115.021 66.5964 115.097 65.9397 115.002C65.2831 114.906 64.655 114.642 64.1048 114.23C63.5546 113.817 63.0971 113.268 62.7682 112.624C62.4393 111.981 62.248 111.261 62.2091 110.52L61.8775 104.209L31.9264 93.1418C30.9173 92.769 29.9972 92.1438 29.2358 91.3138C28.4744 90.4838 27.8916 89.4707 27.5317 88.3512C27.007 86.7193 26.9803 84.9387 27.4557 83.2881C27.9312 81.6374 28.8819 80.2098 30.1592 79.2286L54.2437 60.7276L56.8989 75.9646Z" fill="#FFB8B8" />
            <path d="M70.1752 77.4512C64.6382 74.7342 59.6033 77.6856 53.2481 80.0526L52.2524 60.356C58.5629 56.59 64.5794 55.5746 70.1752 58.4978L70.1752 77.4512Z" fill="#6C63FF" />
            <path d="M76.7985 50.7874C82.6502 50.7874 87.3939 45.4758 87.3939 38.9237C87.3939 32.3716 82.6502 27.06 76.7985 27.06C70.9468 27.06 66.2031 32.3716 66.2031 38.9237C66.2031 45.4758 70.9468 50.7874 76.7985 50.7874Z" fill="#FFB8B8" />
            <path d="M89.474 37.4268C78.9837 38.5772 70.9901 36.6562 65.8813 31.1864V26.7814H88.5837L89.474 37.4268Z" fill="#2F2E41" />
        </g>
        <defs>
            <clipPath id="clip0_1510_8897">
                <rect width="353" height="275" fill="white" />
            </clipPath>
        </defs>
    </svg>

    let iconSort = (up: boolean, down: boolean) => <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.64645 6.63139C3.84171 6.82665 4.15829 6.82665 4.35355 6.63139L8 2.98494L11.6464 6.63139C11.8417 6.82665 12.1583 6.82665 12.3536 6.63139C12.5488 6.43612 12.5488 6.11954 12.3536 5.92428L8.35355 1.92428C8.15829 1.72902 7.84171 1.72902 7.64645 1.92428L3.64645 5.92428C3.45118 6.11954 3.45118 6.43612 3.64645 6.63139Z" fill={up ? "#66CB9F" : "#1A202C"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M1 8.77783C1 8.50169 1.22386 8.27783 1.5 8.27783L14.5 8.27783C14.7761 8.27783 15 8.50169 15 8.77783C15 9.05398 14.7761 9.27783 14.5 9.27783L1.5 9.27783C1.22386 9.27783 1 9.05397 1 8.77783Z" fill="#1A202C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.64645 10.9243C3.84171 10.729 4.15829 10.729 4.35355 10.9243L8 14.5707L11.6464 10.9243C11.8417 10.729 12.1583 10.729 12.3536 10.9243C12.5488 11.1195 12.5488 11.4361 12.3536 11.6314L8.35355 15.6314C8.15829 15.8266 7.84171 15.8266 7.64645 15.6314L3.64645 11.6314C3.45118 11.4361 3.45118 11.1195 3.64645 10.9243Z" fill={down ? "#66CB9F" : "#1A202C"} />
    </svg>

    let statusOk = <svg className="status_ok" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1032 1032">
        <path d="M512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512v0c0 282.77-229.23 512-512 512v0zM512 78.769c-239.267 0-433.231 193.964-433.231 433.231s193.964 433.231 433.231 433.231c239.267 0 433.231-193.964 433.231-433.231v0c0-239.267-193.964-433.231-433.231-433.231v0z"></path>
        <path d="M452.923 740.431c-10.883-0.103-20.695-4.604-27.76-11.809l-0.006-0.006-181.169-180.972c-5.833-6.826-9.382-15.756-9.382-25.514 0-21.752 17.633-39.385 39.385-39.385 9.857 0 18.868 3.621 25.776 9.605l-0.049-0.042 180.972 180.972c7.078 7.119 11.453 16.932 11.453 27.767 0 21.693-17.539 39.29-39.21 39.384l-0.009 0z"></path>
        <path d="M452.923 740.431c-0.009 0-0.020 0-0.031 0-21.752 0-39.385-17.633-39.385-39.385 0-10.933 4.454-20.825 11.647-27.961l0.003-0.003 299.126-298.929c7.131-7.131 16.983-11.542 27.865-11.542 21.764 0 39.407 17.643 39.407 39.407 0 10.882-4.411 20.733-11.542 27.865l0-0-299.323 298.732c-7.071 7.211-16.883 11.712-27.747 11.815l-0.019 0z"></path>
    </svg>

    let statusWarning = <svg className="status_warning" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1032 1032">
        <path d="M943.246 1024.197h-862.295c-0.065 0-0.142 0-0.219 0-44.599 0-80.754-36.155-80.754-80.754 0-13.292 3.211-25.833 8.9-36.891l-0.212 0.453 431.148-862.295c13.533-26.74 40.806-44.752 72.285-44.752s58.751 18.012 72.073 44.292l0.212 0.46 431.148 862.295c5.477 10.605 8.688 23.146 8.688 36.438 0 44.599-36.155 80.754-80.754 80.754-0.077 0-0.154-0-0.231-0l0.012 0zM512.098 78.784c-0.048-0.004-0.105-0.007-0.162-0.007-0.805 0-1.498 0.483-1.803 1.176l-0.005 0.013-431.344 862.295s0 0 0 1.97c0.295 0.593 0.897 0.994 1.592 0.994 0.063 0 0.126-0.003 0.188-0.010l-0.008 0.001h862.689c0.054 0.006 0.117 0.009 0.18 0.009 0.696 0 1.298-0.401 1.588-0.984l0.005-0.010s0-1.576 0-1.97l-430.951-862.295c-0.31-0.705-1.003-1.188-1.808-1.188-0.057 0-0.113 0.002-0.169 0.007l0.007-0z"></path>
        <path d="M568.232 660.804h-112.071l-6.303-241.474h124.479l-6.106 241.474z"></path>
        <path d="M508.947 714.771h6.303c32.634 0 59.088 26.455 59.088 59.088v6.303c0 32.634-26.455 59.088-59.088 59.088h-6.303c-32.634 0-59.088-26.455-59.088-59.088v-6.303c0-32.634 26.455-59.088 59.088-59.088z"></path>
    </svg>

    let statusDownload = <svg className="status-download" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1032 1032">
        <path d="M726.272 533.056c-8.704 0-17.312-2.528-24.704-7.376l-64.96-42.592c-15.184-9.952-22.896-28.032-19.6-45.872s16.992-31.968 34.72-35.824l106.336-23.136c17.728-3.872 36.048 3.296 46.464 18.16s10.912 34.512 1.232 49.872l-41.376 65.712c-6.448 10.24-16.736 17.44-28.544 20.016-3.168 0.704-6.384 1.040-9.568 1.040z"></path>
        <path d="M256.352 649.328c-13.504 0-26.544-6.080-35.232-16.976-11.312-14.192-13.008-33.776-4.304-49.712l37.28-68.144c5.808-10.608 15.632-18.448 27.264-21.728 11.632-3.296 24.112-1.76 34.608 4.224l67.456 38.512c15.76 9.008 24.576 26.576 22.384 44.592-2.208 18.016-14.992 32.944-32.464 37.888l-52.352 14.816c0 0 0 0 0 0l-52.368 14.816c-4.064 1.168-8.192 1.712-12.272 1.712z"></path>
        <path d="M725.728 500.192c-19.936 0-38.176-13.344-43.52-33.52-20.448-77.136-90.544-131.024-170.48-131.024-66.528 0-126.704 36.816-157.072 96.064-11.344 22.144-38.496 30.896-60.64 19.552s-30.896-38.496-19.552-60.64c45.856-89.488 136.768-145.088 237.264-145.088 120.752 0 226.672 81.44 257.584 198.048 6.368 24.048-7.952 48.72-32.016 55.088-3.856 1.040-7.744 1.52-11.568 1.52z"></path>
        <path d="M511.728 778.464c-118.832 0-224.304-79.808-256.496-194.064-6.752-23.952 7.2-48.832 31.152-55.584s48.832 7.2 55.584 31.152c21.296 75.6 91.104 128.4 169.76 128.4 68.816 0 131.792-40.448 160.448-103.056 10.352-22.624 37.088-32.576 59.712-22.224s32.576 37.088 22.208 59.728c-43.264 94.544-138.4 155.648-242.368 155.648z"></path>
        <path d="M512 952c-242.608 0-440-197.376-440-440s197.392-440 440-440 440 197.392 440 440-197.376 440-440 440zM512 162.112c-192.928 0-349.888 156.96-349.888 349.888s156.96 349.888 349.888 349.888 349.888-156.96 349.888-349.888-156.96-349.888-349.888-349.888z"></path>
    </svg>

    let statusInfo = <svg className="status-info" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1032 1032">
        <path d="M512 330.043c-32.627 0-59.077-26.45-59.077-59.077s26.45-59.077 59.077-59.077c32.627 0 59.077 26.45 59.077 59.077s-26.45 59.077-59.077 59.077z"></path>
        <path d="M531.692 812.111c-21.752 0-39.385-17.633-39.385-39.385v0-256h-39.385c-21.752 0-39.385-17.633-39.385-39.385s17.633-39.385 39.385-39.385v0h78.769c21.752 0 39.385 17.633 39.385 39.385v0 295.385c0 21.752-17.633 39.385-39.385 39.385v0z"></path><path d="M512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512v0c0 282.77-229.23 512-512 512v0zM512 78.769c-239.267 0-433.231 193.964-433.231 433.231s193.964 433.231 433.231 433.231c239.267 0 433.231-193.964 433.231-433.231v0c0-239.267-193.964-433.231-433.231-433.231v0z"></path>
    </svg>

    let iconUserGuide = <svg className="userGuide" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 34 30">
        <title>Руководство пользователя</title>
        <path d="M16 2.667c-4.412 0-8 3.588-8 8h2.667c0-2.941 2.392-5.333 5.333-5.333s5.333 2.392 5.333 5.333c0 2.237-1.382 3.598-2.982 5.174-1.727 1.7-3.685 3.627-3.685 6.826h2.667c0-2.083 1.339-3.4 2.888-4.924 1.772-1.744 3.779-3.721 3.779-7.076 0-4.412-3.588-8-8-8zM14.667 26.667v2.667h2.667v-2.667h-2.667z"></path>
    </svg>


    return {
        iconRole, iconPath, iconMenu, iconSliders, iconVertical, noAccess, iconSort, statusOk, statusWarning, statusDownload, statusInfo, iconInfo, iconUserGuide
    }
}

export default useSVG;