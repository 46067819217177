import "./input.css"

interface FormLineType {
    name: string;
    value: string;
    onChange: any;
    valid?: string;
}

const Input: React.FC<FormLineType> = ({ name, value, onChange, valid }) => {

    return (
        <div>
            <div className="input-name">{name}</div>
            <input value={value} onChange={onChange} type="text" className={`input ${valid ? 'valid-error' : ''}`} autoFocus />
            <div className="input-valid-text">{valid}</div>
        </div>
    )
}

export default Input;