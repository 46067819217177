import { useEffect, useState } from "react";
import "./span-date.css"
import "./DatePicker.css"
import "./Calendar.css"
import DatePicker from 'react-date-picker/dist/entry.nostyle'

interface FormLineType {
    showStartDate: Date;
    setStartDate: any;
    showEndDate: Date;
    setEndDate: any;
    disabled?: boolean;
}

const SpanDate: React.FC<FormLineType> = ({ showStartDate, setStartDate, showEndDate, setEndDate, disabled = false }) => {

    const [showOpenStartDate, setOpenStartDate] = useState<boolean>(false);
    const [showOpenEndDate, setOpenEndDate] = useState<boolean>(false);

    const [showStartDatePicker, setStartDatePicker] = useState<Date>(showStartDate);
    const [showEndDatePicker, setEndDatePicker] = useState<Date>(showEndDate);

    var onButtonHandler = (event: any) => {
        if (event.key === 'Enter') {
            setOpenStartDate(false);
            setOpenEndDate(false);
        };
    };

    useEffect(() => {
        if (!showOpenStartDate) {
            setStartDate(showStartDatePicker);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOpenStartDate])

    useEffect(() => {
        if (!showOpenEndDate) {
            setEndDate(showEndDatePicker);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOpenEndDate])

    useEffect(() => {
        if (showOpenStartDate || showOpenEndDate) {
            document.addEventListener<'keydown'>('keydown', onButtonHandler, false);

            return () => {
                document.removeEventListener<'keydown'>('keydown', onButtonHandler, false);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOpenStartDate || showOpenEndDate]);

    return (
        <div className={`span-date ${disabled ? 'disabled' : ''}`}>
            <div className="span-date-title">Выберите период</div>
            <div className={`span-date-content ${disabled ? 'disabled' : ''}`}>
                <DatePicker
                    value={showStartDatePicker} onChange={(date: Date) => setStartDatePicker(date)}
                    clearIcon={null} calendarIcon={null}
                    className="span-date-picker"
                    format="dd.MM.y"
                    locale="ru-RU"
                    disabled={disabled}
                    onCalendarClose={() => setOpenStartDate(false)}
                    onCalendarOpen={() => setOpenStartDate(true)}
                    isOpen={showOpenStartDate}
                />
                <span>-</span>
                <DatePicker
                    value={new Date(showEndDatePicker)} onChange={(date: Date) => setEndDatePicker(date)}
                    clearIcon={null} calendarIcon={null}
                    className="span-date-picker"
                    format="dd.MM.y"
                    locale="ru-RU"
                    disabled={disabled}
                    onCalendarClose={() => setOpenEndDate(false)}
                    onCalendarOpen={() => setOpenEndDate(true)}
                    isOpen={showOpenEndDate}
                />
            </div>
        </div>
    )
}

export default SpanDate;