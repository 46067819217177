
import Subtab from "../../components/Subtab/Subtab";
import Tab from "../../components/Tab/Tab";
import "./tabs.css"

interface FormLineType {
    showTab: number;
    setTab: any;
    disabled: boolean;
    showSubTab: number;
    setSubTab: any;
}

const Tabs: React.FC<FormLineType> = ({ showTab, setTab, disabled, showSubTab, setSubTab }) => {

    return (
        <div className="group-tabs">
            <div className="tabs">
                <Tab title={"Документы"} id={1} setTab={setTab} showTab={showTab} disabled={disabled} noLine/>
                <Tab title={"Контрагенты"} id={2} setTab={setTab} showTab={showTab} disabled={disabled} />
            </div>
            {showTab === 1 && <div className="subtabs">
                <Subtab title={"Реализация"} id={1} setSubTab={setSubTab} showSubTab={showSubTab} disabled={disabled} />
                <Subtab title={"Договоры"} id={2} setSubTab={setSubTab} showSubTab={showSubTab} disabled={disabled} />
            </div>}
        </div>
    )
}

export default Tabs;