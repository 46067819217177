import AgreementDocs from "./agreementDocs/AgreementDocs";
import RealizationDocs from "./realizationDocs/RealizationDocs";
import "./documents.css"

interface FormLineType {
    showItemsRealizationDocs: any;
    setItemsRealizationDocs: any;
    showItemsAgreementDocs: any;
    setItemsAgreementDocs: any;
    showStartDateRealizationDocs: Date;
    setStartDateRealizationDocs: any;
    showEndDateRealizationDocs: Date;
    setEndDateRealizationDocs: any;
    showStartDateAgreementDocs: Date;
    setStartDateAgreementDocs: any;
    showEndDateAgreementDocs: Date;
    setEndDateAgreementDocs: any;
    generateRealizationDocs: any;
    generateAgreementDocs: any;
    updateRealizationDocs: any;
    updateAgreementDocs: any;
    showLoadingRealizationDocs: boolean;
    showLoadingAgreementDocs: boolean;
    showCheckRealizationDocs: any;
    setCheckRealizationDocs: any;
    showCheckAgreementDocs: any;
    setCheckAgreementDocs: any;
    modal: any;
    setModal: any;
    showSubTab: number;
}

const Documents: React.FC<FormLineType> = ({
    showStartDateRealizationDocs, setStartDateRealizationDocs,
    showEndDateRealizationDocs, setEndDateRealizationDocs,
    showStartDateAgreementDocs, setStartDateAgreementDocs,
    showEndDateAgreementDocs, setEndDateAgreementDocs,
    showItemsRealizationDocs, setItemsRealizationDocs,
    showItemsAgreementDocs, setItemsAgreementDocs,
    generateRealizationDocs, 
    generateAgreementDocs,
    updateRealizationDocs,
    updateAgreementDocs,
    showLoadingRealizationDocs,
    showLoadingAgreementDocs,
    showCheckRealizationDocs,
    setCheckRealizationDocs,
    showCheckAgreementDocs,
    setCheckAgreementDocs,
    modal, setModal, showSubTab
}) => {

    return (
        <div className="documents">
            {showSubTab === 1 && <RealizationDocs
                showStartDate={showStartDateRealizationDocs} setStartDate={setStartDateRealizationDocs}
                showEndDate={showEndDateRealizationDocs} setEndDate={setEndDateRealizationDocs}
                showItemsRealizationDocs={showItemsRealizationDocs} setItemsRealizationDocs={setItemsRealizationDocs}
                generateRealizationDocs={generateRealizationDocs} updateRealizationDocs={updateRealizationDocs}
                showLoadingRealizationDocs={showLoadingRealizationDocs}
                showCheckRealizationDocs={showCheckRealizationDocs} setCheckRealizationDocs={setCheckRealizationDocs}
                modal={modal} setModal={setModal}
            />}
             {showSubTab === 2 && <AgreementDocs
                showStartDate={showStartDateAgreementDocs} setStartDate={setStartDateAgreementDocs}
                showEndDate={showEndDateAgreementDocs} setEndDate={setEndDateAgreementDocs}
                showItemsAgreementDocs={showItemsAgreementDocs} setItemsAgreementDocs={setItemsAgreementDocs}
                generateAgreementDocs={generateAgreementDocs} updateAgreementDocs={updateAgreementDocs}
                showLoadingAgreementDocs={showLoadingAgreementDocs}
                showCheckAgreementDocs={showCheckAgreementDocs} setCheckAgreementDocs={setCheckAgreementDocs}
                setModal={setModal}
            />}
        </div>
    )
}

export default Documents;