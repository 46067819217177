import { useEffect, useState } from "react";
import { API_contractorDelete, API_EDxContractors, API_EDxContractorStatus, API_generateRealizationDocs, API_preparegRealizationDocsInfo, API_preparegAgreementDocsInfo, API_generateAgreementDocs } from "../../API/api";
import DispatchNotification, { IDispatchNotificationProps } from "../../components/DispatchNotification/DispatchNotification";
import useSVG from "../../data/icons/icons";
import Modals from "../../modules/modals/Modals";
import Contragents from "./contragents/Contragents";
import Documents from "./documents/Documents";
import "./workspace.css";
import { IDocument, IItemsAgreementDoc, IItemsRealizationDoc } from "../../types";
interface FormLineType {
    showTab: number;
    showSubTab: number;
    modal: any;
    setModal: any;
    showCompanyID: number;
    showAccessToken: string;
    showInfoCo: any;
    setInfoCo: any;
}

const Workspace: React.FC<FormLineType> = ({ showTab, modal, setModal, showCompanyID, showAccessToken, showInfoCo, setInfoCo, showSubTab }) => {

    const [showStartDateRealizationDocs, setStartDateRealizationDocs] = useState(localStorage.getItem('startDateRealizationDocs') ? new Date(JSON.stringify(localStorage.getItem('startDateRealizationDocs'))) : new Date());
    const [showEndDateRealizationDocs, setEndDateRealizationDocs] = useState(localStorage.getItem('endDateRealizationDocs') ? new Date(JSON.stringify(localStorage.getItem('endDateRealizationDocs'))) : new Date());


    const [showStartDateAgreementDocs, setStartDateAgreementDocs] = useState(localStorage.getItem('startDateAgreementDocs') ? new Date(JSON.stringify(localStorage.getItem('startDateAgreementDocs'))) : new Date());
    const [showEndDateAgreementDocs, setEndDateAgreementDocs] = useState(localStorage.getItem('endDateAgreementDocs') ? new Date(JSON.stringify(localStorage.getItem('endDateAgreementDocs'))) : new Date());

    const [showInfoRealizationDocs, setInfoRealizationDocs] = useState([{}]);
    const [showItemsRealizationDocs, setItemsRealizationDocs] = useState<IItemsRealizationDoc[]>([]);
    const [showCheckRealizationDocs, setCheckRealizationDocs] = useState<string[]>([]);

    const [showInfoAgreementDocs, setInfoAgreementDocs] = useState([{}]);
    const [showItemsAgreementDocs, setItemsAgreementDocs] = useState<IItemsAgreementDoc[]>([]);
    const [showCheckAgreementDocs, setCheckAgreementDocs] = useState<string[]>([]);

    const [showItemsCo, setItemsCo] = useState<any>([]);
    const [showStatusTaxcom, setStatusTaxcom] = useState([{ eDxId: "", eDxStatus: "", edmId: null }]);

    const [showLoadingRealizationDocs, setLoadingRealizationDocs] = useState(false);
    const [showLoadingAgreementDocs, setLoadingAgreementDocs] = useState(false);
    const [showLoadingCo, setLoadingCo] = useState(false);

    const [showDispatchNotification, setDispatchNotification] = useState<IDispatchNotificationProps>({ show: false, type: "success", title: "", context: "" });

    const [showTargetTableScroll, setTargetTableScroll] = useState(false);

    const { statusOk, statusInfo, statusDownload } = useSVG();

    const convertDateFormat = (date: Date) => {
        const moment = require('moment');
        const dateStr = moment(date).format('YYYY-MM-DDTHH:mm:ss.sss');
        return dateStr;
    }

    const realizationRequest = async () => {

        setLoadingRealizationDocs(true);

        var preparegRealizationDocsInfo = await API_preparegRealizationDocsInfo(
            showCompanyID, convertDateFormat(showStartDateRealizationDocs), convertDateFormat(showEndDateRealizationDocs), showAccessToken
        );

        if (preparegRealizationDocsInfo) {
            setItemsRealizationDocs(
                [...preparegRealizationDocsInfo.map((doc: any) => ({
                    id: doc.Number,
                    "doc_num": doc.Number,
                    "posting_date": doc.PostingDate ? doc.PostingDate : "",
                    "co_name": doc.Contractor.FullName ? doc.Contractor.FullName : "",
                    "inn": doc.Contractor.INN ? doc.Contractor.INN : "",
                    "kpp": doc.Contractor.KPP ? doc.Contractor.KPP : "",
                    "num_contract": doc.Agreement.Number ? doc.Agreement.Number : "",
                    "date_contract": doc.Agreement.Date ? doc.Agreement.Date : "",
                    "order_number": doc.PaymentOrders[0]?.Number ? Number(doc.PaymentOrders[0]?.Number) : null,
                    "order_date": doc.PaymentOrders[0]?.Date ? doc.PaymentOrders[0]?.Date : "",
                    "sum_vat": doc.CostWithVAT,
                    "sum_not_vat": doc.CostWithoutVAT,
                    "service_description": doc.Services[0].Description ? doc.Services[0].Description : "",
                    "is_upd": doc.IsUpd
                }))]
            )
            setInfoRealizationDocs(preparegRealizationDocsInfo);
        }

        setLoadingRealizationDocs(false);
    }

    const agreementsRequest = async () => {

        setLoadingAgreementDocs(true);

        var preparegAgreementDocsInfo = await API_preparegAgreementDocsInfo(
            showCompanyID, convertDateFormat(showStartDateAgreementDocs), convertDateFormat(showEndDateAgreementDocs), showAccessToken
        );

        if (preparegAgreementDocsInfo) {
            setItemsAgreementDocs(
                [...preparegAgreementDocsInfo.map((agreements: any) => ({
                    id: agreements.ID,
                    disabled: !agreements.ContractorID ? true : false,
                    "radio_station_name": agreements.RadioStationName ? agreements.RadioStationName : "",
                    "city_name": agreements.CityName ? agreements.CityName : "",
                    "agreement_num": agreements.AgreementNumber ? agreements.AgreementNumber : "",
                    "agreement_start_date": agreements.AgreementStartDate ? agreements.AgreementStartDate : "",
                    "agreement_end_date": agreements.AgreementEndDate ? agreements.AgreementEndDate : "",
                    "amount": agreements.Amount ? agreements.Amount : 0,
                    "agreement_add_num": agreements.AgreementAdditionalNumber ? agreements.AgreementAdditionalNumber : "",
                    "agreement_add_start_date": agreements.AgreementAdditionalStartDate ? agreements.AgreementAdditionalStartDate : "",
                    "agreement_add_end_date": agreements.AgreementAdditionalEndDate ? agreements.AgreementAdditionalEndDate : "",
                    "co_name": agreements.ContractorShortName ? agreements.ContractorShortName : "",
                    "co_inn": agreements.ContractorINN ? agreements.ContractorINN : "",
                    "co_kpp": agreements.ContractorKPP ? agreements.ContractorKPP : "",
                    "agreement_additional_status": agreements.AgreementAdditionalStatus ? agreements.AgreementAdditionalStatus : "",
                    "comment": agreements.Comment ? agreements.Comment : "",
                }))]
            )
            setLoadingAgreementDocs(false);
            setInfoAgreementDocs(preparegAgreementDocsInfo);
        }
    }

    const contragentsRequest = async () => {
        setLoadingCo(true);
        var EDxContractors = await API_EDxContractors(showCompanyID, showAccessToken);
        if (EDxContractors) {
            setInfoCo([...EDxContractors]);
            setLoadingCo(false);
        }
    }

    const deleteContragent = async (id: number) => {
        setItemsCo(showItemsCo.filter((co: any) => co.id !== id));
        setInfoCo(showInfoCo.filter((co: any) => co.iD !== id));
        await API_contractorDelete(id, showAccessToken);
    }

    const generateRealizationDocs = async () => {

        setLoadingRealizationDocs(true);
        const checkAndTypeReaizationDoc: IDocument[] = showItemsRealizationDocs.filter((item) => showCheckRealizationDocs.includes(item.id)).map((item) => ({ number: item.id, isUpd: item.is_upd }));

        var request = await API_generateRealizationDocs(
            showCompanyID, convertDateFormat(showStartDateRealizationDocs), convertDateFormat(showEndDateRealizationDocs), checkAndTypeReaizationDoc, showAccessToken
        );

        if (request.status === 200) {
            setCheckRealizationDocs([]);

            var statusUpload = request.data.map((realization: any) =>
                realization.GenerateDocsStatus
            ).includes("Error")

            if (!statusUpload) {
                setDispatchNotification({
                    show: true, type: "success",
                    title: "Реализация отправлена", context: "Вся выбранная реализация была отправлена",
                    buttonText: "Подробнее", buttonClick: () => setModal({ modalResume: true, modalID: 5, payload: { data: request.data } })
                })
                setTimeout(() => {
                    setDispatchNotification({ show: false, type: "success", title: "", context: "" })
                }, 5000);
            }
            else {
                setDispatchNotification({
                    show: true, type: "error",
                    title: "Ошибка отправки реализации", context: "Не вся выбранная реализация была отправлена",
                    buttonText: "Подробнее", buttonClick: () => setModal({ modalResume: true, modalID: 5, payload: { data: request.data } })
                })
                setTimeout(() => {
                    setDispatchNotification({ show: false, type: "error", title: "", context: "" })
                }, 5000);
            }

        }
        else {

            const handleButtonClick = () => {
                const email = 'support@emg.fm';
                const subject = 'Ошибка выгрузки в DocEDO';
                const body = `Здравствуйте!\n\nВо время работы произошла ошибка при попытке выгрузки документов!\n\n—-Приложить скриншот—-`;

                const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                window.open(mailtoUrl);
            };

            setDispatchNotification({
                show: true, type: "error",
                title: "Реализация не выгружена", context: "Произошла ошибка, реализация не была выгружена",
                buttonText: "За помощью", buttonClick: handleButtonClick
            })
            setTimeout(() => {
                setDispatchNotification({ show: false, type: "error", title: "", context: "" })
            }, 5000);
        }

        setLoadingRealizationDocs(false);
    }

    const generateAgreementDocs = async () => {

        setLoadingAgreementDocs(true);
        const checkAndTypeAgreementDoc: IDocument[] = showItemsAgreementDocs.filter((item) => showCheckAgreementDocs.includes(item.id)).map((item) => ({ number: item.id, isUpd: null }));


        var request = await API_generateAgreementDocs(
            showCompanyID, convertDateFormat(showStartDateAgreementDocs), convertDateFormat(showEndDateAgreementDocs), checkAndTypeAgreementDoc, showAccessToken
        );

        if (request.status === 200) {
            setCheckRealizationDocs([]);

            var statusUpload = request.data.map((agreement: any) =>
                agreement.GenerateDocsStatus
            ).includes("Error")

            if (!statusUpload) {
                setDispatchNotification({
                    show: true, type: "success",
                    title: "Договоры отправлены", context: "Все выбранные договоры были отправлены",
                    buttonText: "Подробнее", buttonClick: () => setModal({ modalResume: true, modalID: 6, payload: { data: request.data } })
                })
                setTimeout(() => {
                    setDispatchNotification({ show: false, type: "success", title: "", context: "" })
                }, 5000);
            }
            else {
                setDispatchNotification({
                    show: true, type: "error",
                    title: "Ошибка отправки договоров", context: "Не все выбранные договоры были отправлены",
                    buttonText: "Подробнее", buttonClick: () => setModal({ modalResume: true, modalID: 6, payload: { data: request.data } })
                })
                setTimeout(() => {
                    setDispatchNotification({ show: false, type: "error", title: "", context: "" })
                }, 5000);
            }

        }
        else {
            setDispatchNotification({ show: true, type: "error", title: "Договоры не выгружены", context: "Произошла ошибка, договоры не были выгружены" })
            setTimeout(() => {
                setDispatchNotification({ show: false, type: "error", title: "", context: "" })
            }, 5000);
        }
        setLoadingAgreementDocs(false);
    }

    const updateContractorStatus = async (contractorID?: number) => {
        if (showCompanyID && showAccessToken) {
            var EDxContractorStatus = await API_EDxContractorStatus(showCompanyID, showAccessToken, contractorID)
            setStatusTaxcom(EDxContractorStatus.data);
        }
    }

    const updateRealizationDocs = () => {
        if (showCheckRealizationDocs.length > 0)
            setCheckRealizationDocs(showItemsRealizationDocs.map((docs: any) => docs.id).filter((id: string) => showCheckRealizationDocs.includes(id)))
        realizationRequest();
    }

    const updateAgreementDocs = () => {
        if (showCheckAgreementDocs.length > 0)
            setCheckAgreementDocs(showItemsAgreementDocs.map((docs: any) => docs.id).filter((id: string) => showCheckAgreementDocs.includes(id)))
        agreementsRequest();
    }

    const silentUpdateIdentifierContragent = async (id: number, eDxIdentifier: string) => {
        setInfoCo([...showInfoCo.filter((co: any) => co.iD === id ? co.eDxIdentifier = eDxIdentifier : co)]);
        await updateContractorStatus();
    };

    const silentUpdateActiveContragent = async () => {
        var EDxContractors = await API_EDxContractors(showCompanyID, showAccessToken);
        if (EDxContractors) {
            setInfoCo([...EDxContractors]);
        }
        setTargetTableScroll(true);
        realizationRequest();
        agreementsRequest();
    }

    useEffect(() => {
        if (Object.keys(showInfoCo).length)
            setItemsCo([...showInfoCo.map((co: any) => ({
                id: co.iD,
                "id_edm": co.iD_Contractor,
                "contragent_name": co.fullName,
                "inn": co.iNN,
                "kpp": co.kPP,
                "eDxOperator": co.eDxOperator,
                "num_edo": co.eDxIdentifier ? co.eDxIdentifier : "Отсутствует",
                "status_taxcom": showItemsCo.map((itemsCo: any) => itemsCo.id === co.iD && (showStatusTaxcom.length !== 0 ? itemsCo.status_taxcom : statusDownload))
            }))])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showInfoCo]);

    useEffect(() => {

        const status = (item: string) => {
            switch (item) {
                // case "Loading":
                //   return statusDownload;
                //   break;

                case "Info":
                    return statusInfo;

                case "Accepted":
                    return statusOk;

                default:
                    // return statusWarning;
                    return statusDownload;
            }
        };

        if (showStatusTaxcom.length === 0) {
            setItemsCo((prevState: any) => prevState.map((item: any) => { return { ...item, status_taxcom: statusDownload } }));
        }
        else {
            const statusTaxcom = (item: any) => {
                var _statusTaxcom = showStatusTaxcom.find((statusTaxcom: any) => statusTaxcom.edmId === item.id_edm && statusTaxcom.eDxId === item.num_edo)?.eDxStatus;
                if (_statusTaxcom === undefined)
                    return { ...item, status_taxcom: statusInfo };
                else return { ...item, status_taxcom: status(_statusTaxcom) };
            };

            setItemsCo((prevState: any) => prevState.map((item: any) => statusTaxcom(item)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showStatusTaxcom]);

    useEffect(() => {
        if (showStartDateRealizationDocs && showEndDateRealizationDocs && showAccessToken) {
            realizationRequest();
            contragentsRequest();
        }
        localStorage.setItem('startDateRealizationDocs', showStartDateRealizationDocs.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showStartDateRealizationDocs]);

    useEffect(() => {
        if (showStartDateRealizationDocs && showEndDateRealizationDocs && showAccessToken) {
            realizationRequest();
            contragentsRequest();
        }
        localStorage.setItem('endDateRealizationDocs', showEndDateRealizationDocs.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showEndDateRealizationDocs]);

    useEffect(() => {
        if (showStartDateAgreementDocs && showEndDateAgreementDocs && showAccessToken) {
            agreementsRequest();
        }
        localStorage.setItem('startDateAgreementDocs', showStartDateAgreementDocs.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showStartDateAgreementDocs]);

    useEffect(() => {
        if (showStartDateAgreementDocs && showEndDateAgreementDocs && showAccessToken) {
            agreementsRequest();
        }
        localStorage.setItem('endDateAgreementDocs', showEndDateAgreementDocs.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showEndDateAgreementDocs]);

    useEffect(() => {
        setStatusTaxcom([]);
        setCheckRealizationDocs([]);
        setCheckAgreementDocs([]);
        if (showStartDateRealizationDocs && showEndDateRealizationDocs && showAccessToken) {
            realizationRequest();
            contragentsRequest();
        }
        if (showStartDateAgreementDocs && showEndDateAgreementDocs && showAccessToken) {
            agreementsRequest();
        }
        updateContractorStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCompanyID]);

    useEffect(() => {
        if (!localStorage.getItem('startDateRealizationDocs')) {
            let now = new Date();
            now.setDate(1);
            now.setMonth(now.getMonth() - 1)
            setStartDateRealizationDocs(now);
        }
        if (!localStorage.getItem('startDateAgreementDocs')) {
            let now = new Date();
            now.setDate(1);
            now.setMonth(now.getMonth() - 1)
            setStartDateRealizationDocs(now);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="workspace" >

            {showTab === 1 &&
                <Documents
                    showSubTab={showSubTab}
                    showStartDateRealizationDocs={showStartDateRealizationDocs} setStartDateRealizationDocs={setStartDateRealizationDocs}
                    showEndDateRealizationDocs={showEndDateRealizationDocs} setEndDateRealizationDocs={setEndDateRealizationDocs}
                    showStartDateAgreementDocs={showStartDateAgreementDocs} setStartDateAgreementDocs={setStartDateAgreementDocs}
                    showEndDateAgreementDocs={showEndDateAgreementDocs} setEndDateAgreementDocs={setEndDateAgreementDocs}

                    showItemsRealizationDocs={showItemsRealizationDocs} setItemsRealizationDocs={setItemsRealizationDocs}
                    showItemsAgreementDocs={showItemsAgreementDocs} setItemsAgreementDocs={setItemsAgreementDocs}
                    generateRealizationDocs={generateRealizationDocs} generateAgreementDocs={generateAgreementDocs}
                    updateRealizationDocs={updateRealizationDocs} updateAgreementDocs={updateAgreementDocs}
                    showLoadingRealizationDocs={showLoadingRealizationDocs} showLoadingAgreementDocs={showLoadingAgreementDocs}
                    showCheckRealizationDocs={showCheckRealizationDocs} setCheckRealizationDocs={setCheckRealizationDocs}
                    showCheckAgreementDocs={showCheckAgreementDocs} setCheckAgreementDocs={setCheckAgreementDocs}
                    modal={modal} setModal={setModal}
                />
            }

            {showTab === 2 &&
                <Contragents
                    modal={modal} setModal={setModal}
                    showInfoCo={showInfoCo}
                    showItemsCo={showItemsCo} setItemsCo={setItemsCo} deleteContragent={deleteContragent}
                    showLoadingCo={showLoadingCo}
                    setTargetTableScroll={setTargetTableScroll}
                    showTargetTableScroll={showTargetTableScroll}
                />
            }

            {modal.modalResume &&
                <Modals
                    modal={modal} setModal={setModal}
                    showAccessToken={showAccessToken}
                    showCompanyID={showCompanyID}
                    showInfoCo={showInfoCo}
                    showInfoRealizationDocs={showInfoRealizationDocs}
                    showInfoAgreementDocs={showInfoAgreementDocs}
                    setInfoCo={setInfoCo}
                    silentUpdateActiveContragent={silentUpdateActiveContragent}
                    setDispatchNotification={setDispatchNotification}
                    silentUpdateIdentifierContragent={silentUpdateIdentifierContragent}
                    generateAgreementDocs={generateAgreementDocs} generateRealizationDocs={generateRealizationDocs}
                />
            }
            <DispatchNotification {...showDispatchNotification} />

        </div>
    )
}

export default Workspace;