import "./logo.css"

interface FormLineType {
}

const Logo: React.FC<FormLineType> = () => {

    return (
        <div className="logo">
            <img alt="EMG logo" src="/images/EMG_logo.png" />
            <div className='logo-line'></div>
            <div className='logo-text'>Выгрузка документов</div>
        </div>
    )
}

export default Logo;