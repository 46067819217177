interface FormLineType {
    showListItems: any;
    showCheckIdList: any;
    options: any;
    headerType: any;
}

const TableRowTotalCell: React.FC<FormLineType> = ({ showListItems, showCheckIdList, options, headerType }) => {

    const summary = (type: string) => {
        var sum: number = 0;
        showListItems.filter((item: any) => {
            showCheckIdList.filter((id: number) => id === item.id ? (sum = sum + item[type]) : null)
        })
        return sum.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.');
    }

    return (
        <div className="table__row__total__cell" style={{ width: options[headerType] }}>
            <div className="table__row__total__cell__text">{headerType === "sum_vat" || headerType === "sum_not_vat" ||  headerType === "amount" ? summary(headerType) : ""}</div>
        </div>
    )
};

export default TableRowTotalCell;