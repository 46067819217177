import Table from "../../../../components/Table/Table";
import "./realizationDocs.css";

import headersRealizationDocs from "../../../../data/json/tabDoc/realizationDocs/headersRealizationDocs.json";
import optionsRealizationDocs from "../../../../data/json/tabDoc/realizationDocs/optionsRealizationDocs.json";
import HeaderRealizationDocs from "../../../../modules/headers/HeaderRealizationDocs";
import { useEffect, useState } from "react";

interface FormLineType {
    showItemsRealizationDocs: any;
    setItemsRealizationDocs: any;
    showStartDate: Date;
    setStartDate: any;
    showEndDate: Date;
    setEndDate: any;
    generateRealizationDocs: any;
    updateRealizationDocs: any;
    showLoadingRealizationDocs: boolean;
    showCheckRealizationDocs: any;
    setCheckRealizationDocs: any;
    modal: any;
    setModal: any;
}

const RealizationDocs: React.FC<FormLineType> = ({
    showStartDate, setStartDate,
    showEndDate, setEndDate,
    showItemsRealizationDocs, setItemsRealizationDocs,
    generateRealizationDocs, updateRealizationDocs,
    showLoadingRealizationDocs,
    showCheckRealizationDocs, setCheckRealizationDocs,
    modal, setModal
}) => {

    const [showViewRealizationDocs, setViewRealizationDocs] = useState(showItemsRealizationDocs);

    const onDoubleClick = (id: any) => {
        setModal({ modalResume: true, modalID: 3, payload: { id: id } })
    }

    useEffect(() => {
        setViewRealizationDocs(showItemsRealizationDocs);
    }, [showItemsRealizationDocs]);

    return (
        <div className="realization-docs">
            <HeaderRealizationDocs
                showStartDate={showStartDate} setStartDate={setStartDate}
                showEndDate={showEndDate} setEndDate={setEndDate}
                generateRealizationDocs={generateRealizationDocs} updateRealizationDocs={updateRealizationDocs}
                showLoadingRealizationDocs={showLoadingRealizationDocs} showCheckRealizationDocsLength={showCheckRealizationDocs.length}
                showItemsRealizationDocs={showItemsRealizationDocs} setViewRealizationDocs={setViewRealizationDocs}
            />
            <Table
                showLoading={showLoadingRealizationDocs}
                showListItems={showViewRealizationDocs} setListItems={setItemsRealizationDocs}
                headers={headersRealizationDocs} options={optionsRealizationDocs}
                onCheckbox={true} onDoubleClick={onDoubleClick}
                showCheckIdList={showCheckRealizationDocs} setCheckIdList={setCheckRealizationDocs}
            />
        </div>
    )
}

export default RealizationDocs;