import { useEffect, useState } from "react";
import useSVG from "../../../../data/icons/icons";

interface FormLineType {
  item: string,
  sorting?: any,
  keyItem: string,
  order: number,
  showTypeSort: string;
  setTypeSort: (value: string) => void;
  width: number;
}

const TableHeaderCell: React.FC<FormLineType> = ({
  item, sorting, keyItem, order, width, showTypeSort, setTypeSort
}) => {

  const [showOrder, setOrder] = useState(true);
  const [showViewSort, setViewSort] = useState({ up: false, down: false });

  // const [tWidth, setWidth] = useState(width);

  const { iconSort } = useSVG();

  const onSorting = () => {
    setOrder(!showOrder);
    setViewSort({ up: !showOrder, down: showOrder });
    sorting(keyItem, showOrder);
    setTypeSort(keyItem);
  }

  useEffect(() => {
    if (showTypeSort !== keyItem)
      setViewSort({ up: false, down: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  // const stretchCell = (xMouse: number) => {

  //   const listener = (e: any) => {
  //     options[keyItem] = e.clientX - xMouse + width;
  //     // setWidth(e.clientX - xMouse + width);
  //   };

  //   document.addEventListener('mousemove', listener);

  //   const removeMove = () => {
  //     document.removeEventListener('mousemove', listener);
  //     document.removeEventListener('mouseup', removeMove);
  //   }

  //   document.addEventListener('mouseup', removeMove);

  // }

  // var listener = function (e: any) {
  //   console.log(e.clientX)
  //   setWidth(e.clientX);
  // };

  // const listener = (e: any) => {
  //   console.log(e)
  //   setWidth(e.clientX);
  // };

  return (
    <div className="table__header__cell" >
      <div className="header__cell" onClick={onSorting} title={item} style={{ width: width }}>
        {item}
        {iconSort(showViewSort.up, showViewSort.down)}
      </div>
      {/* <div className="resizer" onMouseDown={(e: any) => stretchCell(e.clientX)} id="resizer"></div> */}
    </div>
  )
}


export default TableHeaderCell;