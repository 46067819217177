import Button from "../../components/Button/Button";
import useSVG from "../../data/icons/icons";
import "./no-access.css"

interface FormLineType {

}

const NoAccess: React.FC<FormLineType> = () => {

    const { noAccess } = useSVG();

    var goToMail = function () {
        window.open("mailto:support@emg.fm?subject=subject&body=body", "_self");
    }

    return (
        <div className="no-access">
            <div className="no-access-image">{noAccess}</div>
            <div className="no-access-text">У вас еще не настроен доступ к документам</div>
            <div className="no-access-button"><Button text={"Запросить доступ"} classStyle={"purple-fill"} onClick={() => goToMail()} /></div>
        </div>
    )
}

export default NoAccess;