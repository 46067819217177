interface FormLineType {
  item: any;
  name: string;
  width: number;
}

const TableCell: React.FC<FormLineType> = ({
  item, name, width
}) => {

  return (
    <div className="table__cell" >
      <div id={name} style={{ width: width }} className="cell" title={typeof item !== "object"? item : null}>
        {item}
      </div>
    </div>
  )
}


export default TableCell;