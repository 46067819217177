import axios from "axios";

let identityUrl = "https://identity.emg.fm:5000/api/";

export const API_identityToken = async () => {
    try {
        const response: any = await axios.request({
            url: "Login/windows/?clientId=emgClient&resource=emgIdentityResource&scope=openid profile emgIdentityResource offline_access",
            baseURL: identityUrl,
            method: 'get',
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });
        return response;
    } catch (error) {
        return error
        // console.log(error);
    }
}

export const API_refreshToken = async (refreshToken: string) => {
    try {
        const response: any = await axios.request({
            url: "token/refresh/emgClient/" + refreshToken,
            baseURL: identityUrl,
            method: 'get',
            withCredentials: true,
            // data: {
            //     "ClientId": "emgClient",
            //     "RefreshToken": refreshToken
            // },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}