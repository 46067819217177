import "./drop-menu.css"

interface FormLineType {
    options: any;
    open: boolean;
}

const DropMenu: React.FC<FormLineType> = ({ options, open }) => {

    return (
        <div className={`drop-menu ${open ? 'open' : 'close'}`}>
            {open && options.map((option: any, key: number) =>
                <div className="drop-menu-item" onClick={() => option.onClick()} key={key}>
                    <span className={`drop-menu-item-text ${option.color}`} >{option.value}</span>
                </div>
            )}
        </div>
    );
}

export default DropMenu;