import "./agreementDocDetailModal.css"
import Button from '../../../components/Button/Button';
import { useEffect} from "react";
import Modal from "../../../components/Modal/Modal";

interface FormLineType {
    modal: any;
    setModal: any;
    showInfoAgreementDocs: any;
}

const AgreementDocDetailModal: React.FC<FormLineType> = ({
    modal, setModal, showInfoAgreementDocs
}) => {

    const docDetails = showInfoAgreementDocs.find((doc: any) => doc.ID === modal.payload.id)

    var onButtonHandler = (event: any) => {
        if (event.key === 'Escape') {
            setModal({ ...modal, modalResume: false, modalID: 0, payload: {} });
        }
    }

    useEffect(() => {
        document.addEventListener<'keydown'>('keydown', onButtonHandler, false);

        return () => {
            document.removeEventListener<'keydown'>('keydown', onButtonHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal modal={modal} >
            <div className='agreementDocDetailModal' >
                <div className="modal-title">Доп. соглашение № {docDetails.AgreementAdditionalNumber} к договору {docDetails.AgreementNumber}</div>
                <div >
                    <div className="modal-content">
                        <div>
                            <div className="modal-text-title">Радиостанция  {docDetails.RadioStationName ? docDetails.RadioStationName : "-"}</div>
                            <div className="modal-content-block">
                                <div className="modal-text"><span className="modal-header-text">Пункт вещания: </span>{docDetails.CityName ? docDetails.CityName : "-"}</div>
                            </div>
                        </div>
                        <div>
                            <div className="modal-text-title">Компания {docDetails?.CompanyFullName ? docDetails.CompanyFullName : "-"}</div>
                            <div className="modal-content-block">
                                <div className="modal-text"><span className="modal-header-text">Адрес: </span>{docDetails.CompanyAddress ? docDetails.CompanyAddress : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">ИНН/КПП: </span>{docDetails.CompanyINN ? docDetails.CompanyINN : "-"} / {docDetails.CompanyKPP ? docDetails.CompanyKPP : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Номер участника ЭДО: </span>{docDetails.CompanyEDxID ? docDetails.CompanyEDxID : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Подписант: </span>{docDetails.CompanyDGLastName ? `${docDetails.CompanyDGLastName} ${docDetails.CompanyDGFirstName} ${docDetails.CompanyDGPatronymic}` : "-"}</div>
                            </div>
                        </div>
                        <div>
                            <div className="modal-text-title">Контрагент {docDetails.ContractorFullName ? docDetails.ContractorFullName : "-"}</div>
                            <div className="modal-content-block">
                                <div className="modal-text-info"><span className="modal-header-text">Код EDM: </span>{docDetails.ContractorID ? docDetails.ContractorID : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Адрес: </span>{docDetails.ContractorAddress ? docDetails.ContractorAddress : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">ИНН/КПП: </span>{docDetails.ContractorINN ? docDetails.ContractorINN : "-"} / {docDetails.ContractorKPP ? docDetails.ContractorKPP : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Номер участника ЭДО: </span>{docDetails.ContractorEDxID ? docDetails.ContractorEDxID : "-"}</div>
                            </div>
                        </div>
                        <div className="modal-text-info"><span className="modal-header-text">Комментарий: </span>{docDetails.Comment ? docDetails.Comment : "-"}</div>
                    </div>
                </div>
                <div className="agreementDocDetailModal-button">
                    <Button text="Отмена" onClick={() => setModal({ ...modal, modalResume: false, modalID: 0, payload: {} })} />
                </div>
            </div >
        </Modal>
    );
}

export default AgreementDocDetailModal;