import ModalCo from "./modalCo/ModalCo";
import ModalDetailsCo from "./modalEditCo/ModalDetailsCo";
import DocDetailModal from "./docDetailModal/DocDetailModal";
import AgreementDocDetailModal from "./agreementDocDetailModal/AgreementDocDetailModal";
import UnloadAgreementDocsModal from "./unloadModal/UnloadAgreementDocsModal";
import UnloadRealizationDocsModal from "./unloadModal/UnloadRealizationDocsModal";
import UserOptionsModal from "./optionsUserModal/UserOptionsModal";


interface FormLineType {
    modal: any;
    setModal: any;
    showCompanyID: number;
    showAccessToken: string;
    showInfoRealizationDocs: any;
    showInfoAgreementDocs: any;
    showInfoCo: any;
    setInfoCo: any;
    silentUpdateActiveContragent: () => void;
    setDispatchNotification: (value: { show: boolean, type: "success" | "error", title: string, context: string }) => void;
    silentUpdateIdentifierContragent: (value: any, value2: any) => void;
    generateRealizationDocs: () => void;
    generateAgreementDocs: () => void;
}

const Modals: React.FC<FormLineType> = ({
    modal, setModal,
    showCompanyID, showAccessToken,
    showInfoRealizationDocs, showInfoCo,
    showInfoAgreementDocs,
    silentUpdateActiveContragent, setDispatchNotification,
    silentUpdateIdentifierContragent
}) => {

    return (
        <div className="modals" >

            {modal.modalID === 1 &&
                <ModalCo
                    modal={modal} setModal={setModal}
                    showAccessToken={showAccessToken}
                    showCompanyID={showCompanyID}
                    silentUpdateActiveContragent={silentUpdateActiveContragent}
                    setDispatchNotification={setDispatchNotification}
                />
            }

            {modal.modalID === 2 &&
                <ModalDetailsCo
                    modal={modal} setModal={setModal}
                    showAccessToken={showAccessToken}
                    showInfoCo={showInfoCo} showCompanyID={showCompanyID}
                    silentUpdateIdentifierContragent={silentUpdateIdentifierContragent}
                    setDispatchNotification={setDispatchNotification}
                    contractorDetails={showInfoCo.find((Co: any) => Co.iD_Contractor === modal.payload.id)}
                />
            }

            {modal.modalID === 3 &&
                <DocDetailModal
                    modal={modal} setModal={setModal} showInfoRealizationDocs={showInfoRealizationDocs}
                />
            }

            {modal.modalID === 4 &&
                <AgreementDocDetailModal
                    modal={modal} setModal={setModal}
                    showInfoAgreementDocs={showInfoAgreementDocs}
                />
            }

            {modal.modalID === 5 &&
                <UnloadRealizationDocsModal
                    modal={modal} setModal={setModal}
                    setDispatchNotification={setDispatchNotification}
                />
            }

            {modal.modalID === 6 &&
                <UnloadAgreementDocsModal
                    modal={modal} setModal={setModal}
                    setDispatchNotification={setDispatchNotification}
                />
            }

            {modal.modalID === 7 &&
                <UserOptionsModal
                    modal={modal} setModal={setModal}
                />
            }

        </div>
    )
}

export default Modals;