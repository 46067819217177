import LightTableTotalCell from "./light-table-total-cell/light-table-total-cell";

interface FormLineType {
    headers: any;
    options: any;
    items: any;
}

const LightTableTotal: React.FC<FormLineType> = ({
    headers, options, items
}) => {

    const name = (type: string) => {
        switch (type) {
            case "ID":
                return "ИТОГО";
                break;

            case "VAT":
                return items.VAT.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.');
                break;

            case "CostWithoutVat":
                return items.CostWithoutVAT.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.');
                break;

            case "CostWithVat":
                return items.CostWithVAT.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.');
                break;

            default:
                return "";
                break;
        }
    }

    return (
        <div className="light-table-header">
            {headers.map((header: any, key: any) =>
                <LightTableTotalCell title={name(header.type)} width={options[header.type]} key={key} />
            )}
        </div>
    )
};

export default LightTableTotal;