import "./contragents.css";
import Table from "../../../components/Table/Table";

import headersCo from "../../../data/json/tabCo/headersCo.json";
import optionsCO from "../../../data/json/tabCo/optionsCo.json";
import HeaderCO from "../../../modules/headers/HeaderCo";
import { useEffect, useState } from "react";

interface FormLineType {
    modal: any;
    setModal: any;
    showItemsCo: any;
    showInfoCo: any;
    setItemsCo: any;
    deleteContragent: any;
    showLoadingCo: boolean;
    setTargetTableScroll: any;
    showTargetTableScroll: boolean;
}

const Contragents: React.FC<FormLineType> = ({ modal, setModal, showInfoCo, showItemsCo, setItemsCo, deleteContragent, showLoadingCo, setTargetTableScroll, showTargetTableScroll }) => {

    const [showViewContragents, setViewContragents] = useState(showItemsCo);

    const fieldsDropMenu = (id: any) => [
        { value: 'Редактировать', onClick: () => setModal({ modalResume: true, modalID: 2, payload: { id: showItemsCo.find((itemCo: any) => itemCo.id === id).id_edm } }) },
        { value: 'Удалить', onClick: () => deleteContragent(id), color: "color-red" }
    ]

    useEffect(() => {
        setViewContragents(showItemsCo);
    }, [showItemsCo]);

    return (
        <div className="contragents">
            <HeaderCO modal={modal} setModal={setModal} showLoadingCo={showLoadingCo} showItemsCo={showItemsCo} setViewContragents={setViewContragents} />
            <Table
                setListItems={setItemsCo} showListItems={showViewContragents}
                headers={headersCo} options={optionsCO}
                fieldsDropMenu={fieldsDropMenu} showLoading={showLoadingCo}
                showTargetTableScroll={showTargetTableScroll} setTargetTableScroll={setTargetTableScroll}
            />
        </div>
    )
}

export default Contragents;