import "./contacts.css"

interface FormLineType {
}

const Contacts: React.FC<FormLineType> = () => {

    return (
        <div className="contacts">
            Европейская медиагруппа © 2022-2023 | <a href="https://emg.fm">emg.fm</a> | Техподдержка <a href="mailto:support@emg.fm">support@emg.fm</a>
        </div>
    )
}

export default Contacts;