import Table from "../../../../components/Table/Table";
import "./agreementDocs.css";

import headersAgreementDocs from "../../../../data/json/tabDoc/agreementDocs/headersAgreementDocs.json";
import optionsAgreementDocs from "../../../../data/json/tabDoc/agreementDocs/optionsAgreementDocs.json";
import HeaderAgreementDocs from "../../../../modules/headers/HeaderAgreementDocs";
import { useEffect, useState } from "react";

interface FormLineType {
    showItemsAgreementDocs: any;
    setItemsAgreementDocs: any;
    showStartDate: Date;
    setStartDate: any;
    showEndDate: Date;
    setEndDate: any;
    generateAgreementDocs: any;
    updateAgreementDocs: any;
    showLoadingAgreementDocs: boolean;
    showCheckAgreementDocs: any;
    setCheckAgreementDocs: any;
    setModal: any;
}

const AgreementDocs: React.FC<FormLineType> = ({
    showStartDate, setStartDate,
    showEndDate, setEndDate,
    showItemsAgreementDocs, setItemsAgreementDocs,
    generateAgreementDocs, updateAgreementDocs,
    showLoadingAgreementDocs,
    showCheckAgreementDocs, setCheckAgreementDocs,
    setModal
}) => {

    const [showViewAgreementDocs, setViewAgreementDocs] = useState(showItemsAgreementDocs);

    const onDoubleClick = (id: any) => {
        setModal({ modalResume: true, modalID: 4, payload: { id: id } })
    }

    useEffect(() => {
        setViewAgreementDocs(showItemsAgreementDocs);
    }, [showItemsAgreementDocs]);

    return (
        <div className="agreement-docs">
            <HeaderAgreementDocs
                showStartDate={showStartDate} setStartDate={setStartDate}
                showEndDate={showEndDate} setEndDate={setEndDate}
                generateAgreementDocs={generateAgreementDocs} updateAgreementDocs={updateAgreementDocs}
                showLoadingAgreementDocs={showLoadingAgreementDocs} showCheckAgreementDocsLength={showCheckAgreementDocs.length}
                showItemsAgreementDocs={showItemsAgreementDocs} setViewAgreementDocs={setViewAgreementDocs}
            />
            <Table
                showLoading={showLoadingAgreementDocs}
                showListItems={showViewAgreementDocs} setListItems={setItemsAgreementDocs}
                headers={headersAgreementDocs} options={optionsAgreementDocs}
                onCheckbox={true} onDoubleClick={onDoubleClick}
                showCheckIdList={showCheckAgreementDocs} setCheckIdList={setCheckAgreementDocs}
            />
        </div>
    )
}

export default AgreementDocs;