import { useEffect, useState } from "react";
import "./search.css"

interface FormLineType {
    items: any;
    setItems: any;
    fillStyle?: boolean;
    disabled?: boolean;
}

const Search: React.FC<FormLineType> = ({ items, setItems, fillStyle = false, disabled = false }) => {

    const [showValue, setValue] = useState("");

    const searchStyle: string = fillStyle ? "search-fill" : 'search';

    useEffect(() => {
        if (showValue) {
            const filterValues = items.filter((item: any) => {
                return Object.values(item).toString().toLowerCase().includes(showValue.toLowerCase());
            })
            setItems(filterValues)
        }
        else
            setItems(items)
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showValue]);

    return (
        <input className={`${searchStyle} ${disabled ? 'disabled' : ''}`} placeholder="Поиск по названию или ИНН " value={showValue} onChange={(e: any) => setValue(e.target.value)} disabled={disabled}/>
    )
}

export default Search;