import LightTableRowCell from "./light-table-row-cell/light-talbe-row-cell";
// import "./light-table-row.css";

interface FormLineType {
    item: any;
    options: any;
}

const LightTableRow: React.FC<FormLineType> = ({
    item, options
}) => {
    return (
        <div className="light-table-row">
            {Object.keys(item).map((key: any) => <LightTableRowCell key={key} value={item[key]} width={options[key]}/>)}
        </div>
    )
};

export default LightTableRow;