
import TableCell from "./table-cell/table-cell";
import TableCellCheckbox from "./table-cell-checkbox/table-cell-checkbox";
import TableCellFunction from "./table-cell-function/table-cell-function";
import Switch from "../../Switch/Switch";

interface FormLineType {
    document: any;
    index: number;
    options: any;
    onCheckbox: boolean;
    fieldsDropMenu: any;
    check: boolean;
    onChangeCheck: any;
    onDoubleClick: any;
    disabledRow?: boolean;
}

const TableRow: React.FC<FormLineType> = ({ document, index, options, onCheckbox, fieldsDropMenu, check, onChangeCheck, onDoubleClick, disabledRow }) => {

    let timerId: any = null;
    function one() {
        onCheckbox && !disabledRow && onChangeCheck(document.id, check);
        timerId = clearTimeout(timerId);
    }

    function two() {
        onDoubleClick && onDoubleClick(document.id)
        timerId = clearTimeout(timerId);
    }

    function handlerClick(e: any) {
        if (e.target.classList[0] === "slider") return;
        if (e.target.classList[0] === "rightArg" || e.target.classList[0] === "leftArg") return;
        if (e.target.classList[0] === "rightArg-active" || e.target.classList[0] === "leftArg-active") return;
        if (e.target.classList[0] === "input_slider") return;
        if (!timerId) {
            timerId = setTimeout(() => {
                one()
            }, 200);
        } else {
            two()
        }
    }

    const formatItem = (item: any, key: string) => {
        if (key === "date_contract" || key === "posting_date" || key === "order_date" || key === "agreement_start_date" || key === "agreement_end_date" || key === "agreement_add_start_date" || key === "agreement_add_end_date") {
            const myDateFormat = (d: any) => {
                var dd = d.getDate(),
                    mm = d.getMonth() + 1,
                    yyyy = d.getFullYear();
                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;
                return dd + '.' + mm + '.' + yyyy;
            }
            return item ? myDateFormat(new Date(item)) : "";
        }
        if (key === "vat" || key === "sum_not_vat" || key === "sum_vat" || key === "amount")
            return item || item === 0 ? item.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.') : "";
        if (key === "is_upd") {
            return <Switch checked={item} id={document.id} setChecked={() => document.is_upd = !item} leftArg="СЧФ" rightArg="УПД"/>
        }
        return item;
    }

    return (
        <div className={`table__row ${!disabledRow ? 'white' : 'grey'}`} key={index} onClick={(e) => handlerClick(e)}>
            {onCheckbox && <TableCellCheckbox index={index} id={document.id} onCheck={check} disabled={disabledRow} />}

            {Object.keys(document).map((key: string) =>
                key !== "id" && key !== "disabled" && <TableCell key={key} name={key} item={formatItem(document[key], key)} width={options[key]} />
            )}

            {fieldsDropMenu && <TableCellFunction id={document.id} fieldsDropMenu={fieldsDropMenu} />}
        </div>
    )
};

export default TableRow;