import { useEffect, useState } from "react";
import { API_availableCompanyList } from "../../API/api";
import DropMenu from "../../components/DropMenu/DropMenu";
import useSVG from "../../data/icons/icons";
import "./company-list.css"

interface FormLineType {
    showAccessToken: string;
    showCompanyID: number;
    setCompanyID: any;
    setNoAccess: any;
}

const CompanyList: React.FC<FormLineType> = ({ showAccessToken, showCompanyID, setCompanyID, setNoAccess }) => {

    const { iconPath } = useSVG();

    const [showDropMenu, setDropMenu] = useState(false);
    const [showCompanyList, setCompanyList] = useState([]);

    const onClickCompany = (id: number) => {
        setCompanyID(id)
        localStorage.setItem('companyID', id.toString());
    }

    const getCompanyList = async () => {
        var request = await API_availableCompanyList(showAccessToken);
        if (request.data.length) {
            var companyList = request.data.map((company: any) => ({ id: company.ID, value: company.FullName, onClick: () => onClickCompany(company.ID) }))
            setCompanyList(companyList)
            localStorage.getItem('companyID') ? Number(localStorage.getItem('companyID')) : setCompanyID(companyList[0].id);
             
        } else setNoAccess(true)
    }

    useEffect(() => {
        getCompanyList();
    }, []);

    return (
        <div className="fix">
            {showCompanyList.length > 0 && <div className="company-list" onClick={() => setDropMenu(!showDropMenu)} tabIndex={1} onBlur={() => setDropMenu(false)}>
                <div className='logo-line' />
                <div className="company-list-drop">
                    <span className="company-list-drop-name">{showCompanyList.map((company: any) => company.id === showCompanyID && company.value)} </span>
                    <span className="company-list-icon">{iconPath}</span>
                </div>
                <DropMenu options={showCompanyList} open={showDropMenu} />
            </div>}
        </div>
    )
}

export default CompanyList;