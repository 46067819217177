import LightTableHeader from "./light-table-header/LightTableHeader";
import LightTableRow from "./light-table-row/light-table-row";
import LightTableTotal from "./light-table-total/light-table-total";
import "./light-table.css";

interface FormLineType {
    items: any;
    headers: any;
    options: any;
    totals: any;
}

const LightTable: React.FC<FormLineType> = ({
    items, headers, options,totals
}) => {
    return (
        <div className="light-table">
            <LightTableHeader headers={headers} options={options} />
            <div className="light-table-rows">
                {items.map((item: any, key: number) =>
                    <LightTableRow item={item} key={key} options={options} />
                )}
            </div>
            <LightTableTotal headers={headers} options={options} items={totals}/>
        </div>
    )
};

export default LightTable;