import "./userOptionsModal.css"
import Button from '../../../components/Button/Button';
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import Checkbox from "../../../components/Checkbox/Checkbox";

interface FormLineType {
    modal: any;
    setModal: any;
}

const UserOptionsModal: React.FC<FormLineType> = ({
    modal, setModal
}) => {
    const [showDisabledSave, setDisabledSave] = useState({ disabled: true, text: "" });
    const [statusResetDataPicker, setStatusResetDataPicker] = useState<boolean>(false);

    const saveOptions = () => {
        if (statusResetDataPicker) {
            localStorage.removeItem("startDateRealizationDocs");
            localStorage.removeItem("endDateRealizationDocs");
            
            localStorage.removeItem("startDateAgreementDocs");
            localStorage.removeItem("endDateAgreementDocs");

            localStorage.removeItem("startDate");
            localStorage.removeItem("endDate");
        }
        window.location.reload();
    };

    var onButtonHandler = (event: any) => {
        if (event.key === 'Escape') {
            setModal({ ...modal, modalResume: false, modalID: 0, payload: {} });
        };
        if (event.key === 'Enter') {
            if (!showDisabledSave.disabled)
                saveOptions();
        };
    };

    useEffect(() => {
        document.addEventListener<'keydown'>('keydown', onButtonHandler, false);

        return () => {
            document.removeEventListener<'keydown'>('keydown', onButtonHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (statusResetDataPicker) {
            setDisabledSave({ disabled: false, text: "" })
        }
        else setDisabledSave({ disabled: true, text: "" })
    })

    return (
        <Modal modal={modal} >
            <div className='modal_user_options' >
                <div className="modal-title">Настройки пользователя</div>
                <div >
                    <div className="modal-content">
                        <div>
                            <div className="modal-text-title">Сброс настроек</div>
                            <div className="modal-content-block">
                                <div className="modal-text">
                                    <Checkbox index={1} id={1} onCheck={statusResetDataPicker} onChange={setStatusResetDataPicker} /> Сбросить период
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal_user_options_buttons">
                    <Button text="Сохранить" classStyle="grey-fill" onClick={() => saveOptions()} disabled={showDisabledSave.disabled} />
                    <Button text="Отмена" onClick={() => setModal({ ...modal, modalResume: false, modalID: 0, payload: {} })} />
                </div>
            </div >
        </Modal>
    );
}

export default UserOptionsModal;