import { useEffect, useState } from 'react';
import { API_identityToken, API_refreshToken } from './API/identity';
import Contacts from './modules/contacts/Contacts';
import TopMenu from './modules/topMenu/TopMenu';
import Workspace from './pages/workspace/Workspace';
import Lottie from 'react-lottie-player';

import loadingAnim from './data/json/loading/loading.json'
import NoAccess from './pages/noAccess/NoAccess';

import packageJson from '../package.json';

const App = () => {
  const [showAccessToken, setIdentityToken] = useState("");
  const [showTab, setTab] = useState(localStorage.getItem('tab') ? Number(localStorage.getItem('tab')) : 1);
  const [showSubTab, setSubTab] = useState(localStorage.getItem('subtab') ? Number(localStorage.getItem('subtab')) : 1);
  const [modal, setModal] = useState({
    modalResume: false, modalSend: false,
    modalValid: true, modalEdit: false,
    modalID: 0, payload: {}
  });
  const [showCompanyID, setCompanyID] = useState(localStorage.getItem('companyID') ? Number(localStorage.getItem('companyID')) : 0);
  const [showInfoCo, setInfoCo] = useState([]);
  const [showLoadingWorkspace, setLoadingWorkspace] = useState(true);
  const [showNoAccess, setNoAccess] = useState(false);

  const input = async () => {
    const request = await API_identityToken();
    //Временно во время разработки
    if (request.status === 200) {
      if (request.data.access_token) {
        setIdentityToken(request.data.access_token);
        refreshToken(request.data.refresh_token);
      } else {
        console.log("ошибка Access Token", request)
        input();
      }

    } else {
      window.open("https://identity.emg.fm:5000/?returnUrl=https://doc-edo.emg.fm/", "_self");
    }
  }

  const refreshToken = (_refreshToken: string) => {
    if (_refreshToken) {
      setTimeout(async () => {
        var request = await API_refreshToken(_refreshToken);
        if (request.access_token) {
          setIdentityToken(request.access_token);
          refreshToken(request.refresh_token)
        } else {
          console.log("ошибка Refresh Token");
          input();
        }

      }, 300000);
    }
    else console.log("Возникла проблема обновления токена")
  }

  const onMatch = async (data: any) => {
    const versionRegex = /<meta\s+name="version"\s+content="([^"]+)"/;
    const match = await data.match(versionRegex);
    return match
  }

  useEffect(() => {
    let currentVersionJsReloadCount = 0;

    const versionCheck = async () => {
      try {
        const response = await fetch(`${packageJson.version}?rc=${++currentVersionJsReloadCount}`);
        const data = await response.text();
        const match = await onMatch(data);

        if (match) {
          const version = match[1];
          if (version !== packageJson.version) {
            console.log(`${version} > ${packageJson.version}, Устаревшая версия.`)
            alert('Новая версия доступна! Нажмите кнопку "ОК", чтобы обновить страницу.');
            caches.keys().then(function (names) {
              for (let name of names)
                caches.delete(name); // Clear cache
            });
            window.location.reload(); // Reload
          }
        }
      } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
      }
    };

    setInterval(versionCheck, 1800000);

  }, []);


  useEffect(() => {
    input();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <div className='Main'>
        <TopMenu showAccessToken={showAccessToken}
          showTab={showTab} setTab={setTab}
          showSubTab={showSubTab} setSubTab={setSubTab}
          showCompanyID={showCompanyID} setCompanyID={setCompanyID}
          setLoadingWorkspace={setLoadingWorkspace}
          disabledTabs={showLoadingWorkspace || showNoAccess}
          setNoAccess={setNoAccess}
          setModal={setModal}
        />

        {!showLoadingWorkspace && !showNoAccess && <Workspace
          showTab={showTab} showSubTab={showSubTab}
          modal={modal} setModal={setModal}
          showCompanyID={showCompanyID}
          showAccessToken={showAccessToken}
          showInfoCo={showInfoCo} setInfoCo={setInfoCo}
        />}

        {showLoadingWorkspace && !showNoAccess && <div className="player">
          <Lottie loop play
            animationData={loadingAnim}
            style={{ height: '100px', width: '100px' }}
          />
        </div>}

        {showNoAccess && <NoAccess />}

        <Contacts />
      </div>
    </div>
  );
}

export default App;