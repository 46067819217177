import { useState } from "react";
import useSVG from "../../../../data/icons/icons";
import DropMenu from "../../../DropMenu/DropMenu";

interface FormLineType {
  id: number;
  fieldsDropMenu: any;
}

const TableCellFunction: React.FC<FormLineType> = ({id, fieldsDropMenu}) => {

  const [showDropMenu, setDropMenu] = useState(false);
  const { iconVertical } = useSVG();

  const index = showDropMenu ? 10 : 0;
  return (
    <div className="table__cell__function" onClick={() => setDropMenu(!showDropMenu)} style={{ zIndex: index }} tabIndex={1} onBlur={() => setDropMenu(false)}>
      <div className="table__cell_function_icon">{iconVertical}</div>
      <DropMenu options={fieldsDropMenu(id)} open={showDropMenu} />
    </div>
  )
}


export default TableCellFunction;