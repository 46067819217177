import "./modal-details-co.css"
import Button from '../../../components/Button/Button';
import { useEffect, useState } from "react";
import Input from "../../../components/Input/Input";
import { API_editIdentifierContragent } from "../../../API/api";
import Modal from "../../../components/Modal/Modal";
import { IDispatchNotificationProps } from "../../../components/DispatchNotification/DispatchNotification";

interface FormLineType {
    modal: any;
    setModal: any;
    showAccessToken: string;
    showInfoCo: any;
    showCompanyID: number;
    silentUpdateIdentifierContragent: (value: any, value2: any) => void;
    setDispatchNotification: (value: IDispatchNotificationProps) => void;
    contractorDetails: any;
}

const ModalDetailsCo: React.FC<FormLineType> = ({
    modal, setModal, showAccessToken, showInfoCo, showCompanyID, silentUpdateIdentifierContragent,
    setDispatchNotification,
    contractorDetails
}) => {
    const [showIdentifierEDO, setIdentifierEDO] = useState(contractorDetails?.eDxIdentifier);
    const [showDisabledSave, setDisabledSave] = useState({ disabled: true, text: "" });

    const editIdentifierContragent = async () => {
        var request = await API_editIdentifierContragent(contractorDetails.iD, contractorDetails?.iD_Contractor, showCompanyID, showIdentifierEDO, showAccessToken);
        if (request.status === 200) {
            setModal({ ...modal, modalResume: false, modalID: 0, payload: {} })
            silentUpdateIdentifierContragent(contractorDetails.iD, showIdentifierEDO);
        }
        else {
            setDispatchNotification({ show: true, type: "error", title: "Контрагент не изменен", context: "Произошла ошибка, идентификатор контрагента не изменен" })
            setTimeout(() => {
                setDispatchNotification({ show: false, type: "error", title: "", context: "" })
            }, 5000);
        }
    }

    var onButtonHandler = (event: any) => {
        if (event.key === 'Escape') {
            setModal({ ...modal, modalResume: false, modalID: 0, payload: {} });
        }
        if (event.key === 'Enter') {
            if (!showDisabledSave.disabled)
                editIdentifierContragent();
        }
    }

    useEffect(() => {
        document.addEventListener<'keydown'>('keydown', onButtonHandler, false);

        return () => {
            document.removeEventListener<'keydown'>('keydown', onButtonHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDisabledSave]);

    useEffect(() => {
        var valid = contractorDetails?.eDxIdentifier !== showIdentifierEDO ? showIdentifierEDO.match(/^[A-Za-z0-9-]+$/) : false;

        if (valid || showIdentifierEDO === "" || contractorDetails?.eDxIdentifier === showIdentifierEDO) {
            setDisabledSave(contractorDetails?.eDxIdentifier === showIdentifierEDO ? { disabled: true, text: "" } : { disabled: false, text: "" });
        }
        else {
            setDisabledSave({ disabled: true, text: "Используются недопустимые символы" })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showIdentifierEDO]);

    return (
        <Modal modal={modal} >
            <div className='modal-details-co' >
                <div className="modal-title">Реквизиты контрагента</div>
                <div >
                    <div className="modal-content">
                        <div>
                            <div className="modal-text-title">{contractorDetails?.fullName ? contractorDetails.fullName : "-"}</div>
                            <div className="modal-content-block">
                                <div className="modal-text">ИНН {contractorDetails?.iNN ? contractorDetails.iNN : "-"}</div>
                                <div className="modal-text">КПП {contractorDetails?.kPP ? contractorDetails.kPP : "-"}</div>
                            </div>
                        </div>
                        <div>
                            <div className="modal-text-title">Руководитель:</div>
                            <div className="modal-content-block">
                                <div className="modal-text">{contractorDetails?.head ? contractorDetails.head : "-"}</div>
                            </div>
                        </div>
                        <div>
                            <div className="modal-text-title">Номер телефона:</div>
                            <div className="modal-content-block">
                                <div className="modal-text">{contractorDetails?.phoneNumber ? contractorDetails.phoneNumber : "-"}</div>
                            </div>
                        </div>
                        <div>
                            <div className="modal-text-title">Электронная почта:</div>
                            <div className="modal-content-block">
                                <div className="modal-text">{contractorDetails?.email ? contractorDetails.email : "-"}</div>
                            </div>
                        </div>
                        <div>
                            <div className="modal-text-title">Юридический адрес:</div>
                            <div className="modal-content-block">
                                <div className="modal-text">{contractorDetails?.legalAddress ? contractorDetails.legalAddress : "-"}</div>
                            </div>
                        </div>
                        <div>
                            <div className="modal-text-title">Фактический адрес:</div>
                            <div className="modal-content-block">
                                <div className="modal-text">{contractorDetails?.actualAddress ? contractorDetails.actualAddress : "-"}</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <Input name="Идентификатор участника ЭДО" value={showIdentifierEDO} onChange={(e: any) => setIdentifierEDO(e.target.value)} valid={showDisabledSave.text} />
                </div>
                <div className="modal-details-co-buttons">
                    <Button text="Сохранить" classStyle="grey-fill" onClick={() => editIdentifierContragent()} disabled={showDisabledSave.disabled} />
                    <Button text="Отмена" onClick={() => setModal({ ...modal, modalResume: false, modalID: 0, payload: {} })} />
                </div>
            </div >
        </Modal>
    );
}

export default ModalDetailsCo;