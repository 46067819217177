import "./modal-co.css"

import headersCoModal from "./../../../data/json/modalCo/headersCoModal.json";
import optionsCoModal from "./../../../data/json/modalCo/optionsCoModal.json";
import Button from './../../../components/Button/Button';
import Table from "../../../components/Table/Table";
import { useEffect, useState } from "react";
import { API_addActiveContragent, API_shortActiveContractors } from "../../../API/api";
import Search from "../../../components/Search/Search";
import Modal from "../../../components/Modal/Modal";
import { IDispatchNotificationProps } from "../../../components/DispatchNotification/DispatchNotification";

interface FormLineType {
    modal: any;
    setModal: any;
    showAccessToken: string;
    showCompanyID: number;
    silentUpdateActiveContragent: () => void;
    // setDispatchNotification: (value: { show: boolean, type: "success" | "error", title: string, context: string }) => void;
    setDispatchNotification: (value: IDispatchNotificationProps) => void;
}

const ModalCo: React.FC<FormLineType> = ({ modal, setModal, showAccessToken, showCompanyID, silentUpdateActiveContragent, setDispatchNotification }) => {

    const [showModalItemsCo, setModalItemsCo] = useState([]);
    const [showModalViewCo, setModalViewCo] = useState(showModalItemsCo);

    const [showCheckModalItemsCo, setCheckModalItemsCo] = useState([]);

    const [showLoadingModalCo, setLoadingModalCo] = useState(true);

    const shortActiveContractors = async () => {
        setLoadingModalCo(true);
        var request = await API_shortActiveContractors(showAccessToken);
        if (request.status === 200) {
            setModalItemsCo(
                request.data.map((activeCo: any) => ({
                    id: activeCo.iD,
                    "id_edm": activeCo.iD,
                    "contragent_name": activeCo.title,
                    "inn": activeCo.iNN ? activeCo.iNN : "",
                    "kpp": activeCo.kPP ? activeCo.kPP : ""
                }))
            );
        }
        else {
            setDispatchNotification({ show: true, type: "error", title: "Активные контрагенты не загрузились", context: "Произошла ошибка, контрагенты не загрузились, обратитесь в тех поддержку" })
            setTimeout(() => {
                setDispatchNotification({ show: false, type: "error", title: "", context: "" })
            }, 5000);
        }

        if (showModalViewCo)
            setLoadingModalCo(false);
    }

    const addContragent = async () => {
        await showCheckModalItemsCo.map(async (id: any) => {
            var request = await API_addActiveContragent(id, showCompanyID, new Date(), showAccessToken);
            if (request.status !== 200) {
                setDispatchNotification({ show: true, type: "error", title: `Контрагент ${id} не добавлен`, context: "Произошла ошибка, контрагенты не добавлены" })
                setTimeout(() => {
                    setDispatchNotification({ show: false, type: "error", title: "", context: "" })
                }, 5000);
            }
        });
        await silentUpdateActiveContragent();
        if (showCheckModalItemsCo.length === 1)
            await setModal({ modalResume: true, modalID: 2, payload: { id: showCheckModalItemsCo[0] } })
        if (showCheckModalItemsCo.length !== 1)
            setModal({ modalResume: false, modalID: 0, payload: {} });
    }

    function sum(obj: any) {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    const widthOnCheckbox: number = 74;

    var onButtonHandler = (event: any) => {
        if (event.key === 'Escape') {
            setModal({ modalResume: false, modalID: 0, payload: {} });
        }
        if (event.key === 'Enter') {
            if (showCheckModalItemsCo.length > 0)
                addContragent();
        }
    }

    useEffect(() => {
        document.addEventListener<'keydown'>('keydown', onButtonHandler, false);

        return () => {
            document.removeEventListener<'keydown'>('keydown', onButtonHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setModalViewCo(showModalItemsCo);
    }, [showModalItemsCo]);

    useEffect(() => {
        shortActiveContractors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal modal={modal} >
            <div className='modal-co' style={{ width: sum(optionsCoModal) + widthOnCheckbox + 15 + 10 }} >
                <div className="modal-title">Добавление контрагента</div>
                <div className="modal-co-search" >
                    <Search items={showModalItemsCo} setItems={setModalViewCo} />
                </div>
                <Table
                    showLoading={showLoadingModalCo}
                    setListItems={setModalItemsCo} showListItems={showModalViewCo}
                    headers={headersCoModal} options={optionsCoModal} onCheckbox={true}
                    showCheckIdList={showCheckModalItemsCo} setCheckIdList={setCheckModalItemsCo}
                />
                <div className="modal-co-buttons">
                    <Button text="Добавить" classStyle="grey-fill" onClick={() => addContragent()} disabled={showCheckModalItemsCo.length === 0} />
                    <Button text="Отмена" onClick={() => setModal({ ...modal, modalResume: false, modalID: 0, payload: {} })} />
                </div>
            </div>
        </Modal>
    );
}

export default ModalCo;