import { InputHTMLAttributes, useRef, useState, useEffect } from "react";
import "./Switch.css";

export interface ISwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    status?: boolean;
    checked: boolean;
    setChecked: any;
    id: string;
    leftArg?: string;
    rightArg?: string;
}

const Switch = ({ status, checked, setChecked, id, leftArg, rightArg }: ISwitchProps): any => {

    const refInput = useRef<HTMLInputElement>(null);

    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = () => {
        setIsChecked(!isChecked);
        setChecked(id, !isChecked)
    };

    useEffect(() =>{
        setIsChecked(checked)
    }, [checked])

    return (
        <div className="divWithSwitch">
            <label className="switch">
                <input
                    ref={refInput}
                    type="checkbox"
                    checked={isChecked}
                    className="input_slider"
                    onChange={() => handleChange()}
                />
                <span
                    className="slider"
                />
                <span className={isChecked ? "leftArg" : "leftArg-active"}>{leftArg}</span>
                <span className={!isChecked ? "rightArg" : "rightArg-active"}>{rightArg}</span>
            </label>
            {status && (
                <span className="status">{isChecked ? "Active" : "Default"}</span>
            )}
        </div>
    );
}

export default Switch;
