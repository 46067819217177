import "./docDetailModal.css"
import Button from '../../../components/Button/Button';
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import LightTable from "../../../components/LightTable/LightTable";
import headersDocModal from "./../../../data/json/modalDoc/headersDocModal.json";
import optionsDocModal from "./../../../data/json/modalDoc/optionsDocModal.json";

interface FormLineType {
    modal: any;
    setModal: any;
    showInfoRealizationDocs: any;
}

const DocDetailModal: React.FC<FormLineType> = ({ modal, setModal, showInfoRealizationDocs }) => {
    const docDetails = showInfoRealizationDocs.find((doc: any) => doc.Number === modal.payload.id)
    const [showDocDetails, setDocDetails] = useState([{}]);

    const onButtonHandler = (event: any) => {
        if (event.key === 'Escape') {
            setModal({ ...modal, modalResume: false, modalID: 0, payload: {} });
        }
    }

    const dateFormat = (d: any) => {
        var dd = d.getDate(),
            mm = d.getMonth() + 1,
            yyyy = d.getFullYear();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return dd + '.' + mm + '.' + yyyy;
    }

    useEffect(() => {
        document.addEventListener<'keydown'>('keydown', onButtonHandler, false);

        return () => {
            document.removeEventListener<'keydown'>('keydown', onButtonHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (docDetails)
            setDocDetails([...docDetails.Services.map((service: any) => ({
                "ID": service.ID,
                "CostWithoutVat": service.CostWithoutVAT.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.'),
                "CostWithVat": service.CostWithVAT.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.'),
                "VAT": service.VAT.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.'),
                "TaxRate": service.TaxRate + "%",
                "Description": service.Description
            }))])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docDetails]);

    return (
        <Modal modal={modal}>
            <div className='doc-detail-modal' >
                <div className="modal-title">Документ № {docDetails.Number}</div>
                <div className="doc-detail-modal-content">
                    <div className="doc-detail-modal-content-details">
                        <div className="doc-detail-modal-content-details-cell">
                            <div className="modal-text-title">Договор № {docDetails.Agreement.Number ? docDetails.Agreement.Number : "-"}</div>
                            <div className="modal-content-block">
                                <div className="modal-text">
                                    <span className="modal-header-text">от </span>{docDetails.Agreement.Date && docDetails.Agreement.Date !== "2000-01-01T00:00:00" ? dateFormat(new Date(docDetails.Agreement.Date)) : "-"}
                                    <span className="modal-header-text"> до </span>{docDetails.Agreement.EndDate && docDetails.Agreement.EndDate !== "2000-01-01T00:00:00" ? dateFormat(new Date(docDetails.Agreement.EndDate)) : "-"}
                                </div>
                                <div className="modal-text"><span className="modal-header-text">Тип документа: </span>{docDetails.IsUpd ? "УПД" : "Счёт-фактуры"}</div>
                                <div className="modal-text-info"><span className="modal-header-text">Дата публикации: </span>{docDetails.PostingDate && docDetails.PostingDate !== "2000-01-01T00:00:00" ? dateFormat(new Date(docDetails.PostingDate)) : "-"}</div>

                                <div className="modal-text"><span className="modal-header-text">Ответственный: </span>{docDetails.Author ? docDetails.Author : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Дата начала трансляции: </span>{docDetails.BroadcastStartDate && docDetails.BroadcastStartDate !== "2000-01-01T00:00:00" ? dateFormat(new Date(docDetails.BroadcastStartDate)) : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Дата окончания трансляции: </span>{docDetails.BroadcastEndDate && docDetails.BroadcastStartDate !== "2000-01-01T00:00:00" ? dateFormat(new Date(docDetails.BroadcastEndDate)) : "-"}</div>
                            </div>
                        </div>
                        <div className="doc-detail-modal-content-details-cell">
                            <div className="modal-text-title">Компания {docDetails.Company.FullName ? docDetails.Company.FullName : "-"}</div>
                            <div className="modal-content-block">
                                <div className="modal-text"><span className="modal-header-text">Адрес: </span>{docDetails.Company.Address ? docDetails.Company.Address : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">ИНН/КПП: </span>{docDetails.Company.INN ? docDetails.Company.INN : "-"} / {docDetails.Company.KPP ? docDetails.Company.KPP : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Номер участника ЭДО: </span>{docDetails.Company.EDx_ID ? docDetails.Company.EDx_ID : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Подписант: </span>{docDetails.Company.Signer ? `${docDetails.Company.Signer.LastName} ${docDetails.Company.Signer.FirstName} ${docDetails.Company.Signer.Patronymic}` : "-"}</div>
                                <div className="modal-text-info"><span className="modal-header-text">Наименование папки: </span>{docDetails.Company.FolderName ? docDetails.Company.FolderName : "-"}</div>
                                <div className="modal-text-info"><span className="modal-header-text">ID компании: </span>{docDetails.Company.ID ? docDetails.Company.ID : "-"}</div>
                            </div>
                        </div>
                        <div className="doc-detail-modal-content-details-cell">
                            <div className="modal-text-title">Контрагент {docDetails.Contractor.FullName ? docDetails.Contractor.FullName : "-"}</div>
                            <div className="modal-content-block">
                                <div className="modal-text-info"><span className="modal-header-text">Код EDM: </span>{docDetails.Contractor.EDM_ID ? docDetails.Contractor.EDM_ID : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Адрес: </span>{docDetails.Contractor.Address ? docDetails.Contractor.Address : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">ИНН/КПП: </span>{docDetails.Contractor.INN ? docDetails.Contractor.INN : "-"} / {docDetails.Contractor.KPP ? docDetails.Contractor.KPP : "-"}</div>
                                <div className="modal-text"><span className="modal-header-text">Номер участника ЭДО: </span>{docDetails.Contractor.EDx_ID ? docDetails.Contractor.EDx_ID : "-"}</div>
                            </div>
                        </div>
                        <div className="doc-detail-modal-content-details-cell">
                            <div className="modal-text-title">Платежные поручения:</div>
                            <div className="modal-content-block">
                                {docDetails.PaymentOrders[0] ? docDetails.PaymentOrders.map((paymentOrders: any, key: number) => <div className="modal-text" key={key}><span className="modal-header-text">№ </span>{paymentOrders.Number ? paymentOrders.Number : "-"}<span className="modal-header-text"> от </span>{paymentOrders.Date ? dateFormat(new Date(paymentOrders.Date)) : "-"}</div>) : "-"}
                            </div>
                        </div>
                    </div>
                    <div className="doc-detail-modal-content-table">
                        <div className="modal-text-title">Услуги:</div>
                        <LightTable items={showDocDetails} headers={headersDocModal} options={optionsDocModal} totals={docDetails} />
                    </div>
                </div>

            </div >
            <div className="doc-detail-modal-button">
                <Button text="Закрыть" onClick={() => setModal({ ...modal, modalResume: false, modalID: 0, payload: {} })} />
            </div>
        </Modal>
    );
}

export default DocDetailModal;