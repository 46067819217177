import Button from "../../components/Button/Button";
import Search from "../../components/Search/Search";
import SpanDate from "../../components/SpanDate/SpanDate";
import "./header.css";

interface FormLineType {
    showStartDate: Date;
    setStartDate: any;
    showEndDate: Date;
    setEndDate: any;
    generateAgreementDocs: any;
    showLoadingAgreementDocs: boolean;
    updateAgreementDocs: any;
    showCheckAgreementDocsLength: number;
    showItemsAgreementDocs: any;
    setViewAgreementDocs: any;
}


const HeaderAgreementDocs: React.FC<FormLineType> = ({ showStartDate, setStartDate, showEndDate, setEndDate, generateAgreementDocs, showLoadingAgreementDocs, updateAgreementDocs, showCheckAgreementDocsLength,
    showItemsAgreementDocs, setViewAgreementDocs
}) => {

    return (
        <div className="header">
            <div className="header-input">
                <SpanDate showStartDate={showStartDate} setStartDate={setStartDate} showEndDate={showEndDate} setEndDate={setEndDate} disabled={showLoadingAgreementDocs}/>
                <Search items={showItemsAgreementDocs} setItems={setViewAgreementDocs} fillStyle={true} disabled={showLoadingAgreementDocs}/>
            </div>
            <div className="header-buttons">
                <Button text={"Обновить"} classStyle={"purple-outline"} onClick={() => updateAgreementDocs()} disabled={showLoadingAgreementDocs} />
                <Button text={"Выгрузить"} classStyle={"purple-fill"} onClick={() => generateAgreementDocs()} disabled={showLoadingAgreementDocs || showCheckAgreementDocsLength === 0} />
            </div>
        </div>
    )
}

export default HeaderAgreementDocs;