import axios from "axios";
import { IDocument } from "../types";
let docEdoApi = "https://doc-edo-api.emg.fm/api/";

export const API_shortUserInfo = async (identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "User/ShortUserInfo",
            baseURL: docEdoApi,
            method: 'get',
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const API_availableCompanyList = async (identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "CompanyInfo/AvailableCompanyList",
            baseURL: docEdoApi,
            method: 'get',
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response;
    } catch (error) {
        return error
    }
}

export const API_preparegRealizationDocsInfo = async (companyId: number, startDate: Date, endDate: Date, identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "DocsGeneration/PreparegDocInfo",
            baseURL: docEdoApi,
            method: 'get',
            withCredentials: true,
            params: {
                "CompanyId": companyId,
                "StartDate": startDate,
                "EndDate": endDate
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_preparegAgreementDocsInfo = async (companyId: number, startDate: Date, endDate: Date, identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "DocsGeneration/PrepareDocInfoAgreement",
            baseURL: docEdoApi,
            method: 'get',
            withCredentials: true,
            params: {
                "CompanyId": companyId,
                "StartDate": startDate,
                "EndDate": endDate
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const API_EDxContractors = async (companyId: number, identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "Contractors/EDxContractors",
            baseURL: docEdoApi,
            method: 'get',
            withCredentials: true,
            params: {
                "CompanyId": companyId,
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_activeContractors = async (identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "Contractors/ActiveContractors",
            baseURL: docEdoApi,
            method: 'get',
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_shortActiveContractors = async (identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "Contractors/ShortActiveContractors",
            baseURL: docEdoApi,
            method: 'get',
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const API_contractorDelete = async (EDxContractorId: number, identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "Contractors/EDxContractorLink",
            baseURL: docEdoApi,
            method: 'delete',
            withCredentials: true,
            params: {
                "EDxContractorId": EDxContractorId,
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_generateRealizationDocs = async (companyId: number, startDate: Date, endDate: Date, documents: IDocument[], identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "DocsGeneration/GenerateDocs",
            baseURL: docEdoApi,
            method: 'post',
            withCredentials: false,
            data: {
                "companyId": companyId,
                "startDate": startDate,
                "endDate": endDate,
                "documents": documents
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const API_generateAgreementDocs = async (companyId: number, startDate: Date, endDate: Date, documents: IDocument[], identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "DocsGeneration/GenerateAgreementApproval",
            baseURL: docEdoApi,
            method: 'post',
            withCredentials: false,
            data: {
                "companyId": companyId,
                "startDate": startDate,
                "endDate": endDate,
                "documents": documents
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const API_addActiveContragent = async (contragentID: number, companyID: number, eDxStartDate: Date, identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "Contractors/EDxContractorMatrix",
            baseURL: docEdoApi,
            method: 'post',
            withCredentials: true,
            data: {
                "id": 0,
                "iD_Contractor": contragentID,
                "navID_Company": companyID,
                "eDxIdentifier": "",
                "isUseEDx": true,
                "eDxStartDate": eDxStartDate,
                // "iD_ArchiveDocs": null
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const API_editIdentifierContragent = async (id: number, contragentID: number, companyID: number, eDxIdentifier: string, identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: "Contractors/EDxContractorMatrix",
            baseURL: docEdoApi,
            method: 'post',
            withCredentials: true,
            data: {
                "id": id,
                "iD_Contractor": contragentID,
                "navID_Company": companyID,
                "eDxIdentifier": eDxIdentifier,
                "isUseEDx": true,
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const API_EDxContractorStatus = async (companyId: number, identityToken: string, contractorId?: number) => {
    try {
        const response: any = await axios.request({
            url: "Contractors/EDxContractorStatus",
            baseURL: docEdoApi,
            method: 'get',
            withCredentials: true,
            params: {
                "CompanyId": companyId,
                "ContractorId": contractorId
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${identityToken}`
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}