// import "./light-table-row-cell.css";

interface FormLineType {
    value: any;
    width: number;
}

const LightTableRowCell: React.FC<FormLineType> = ({
    value, width
}) => {
    return (
        <div className="light-table-row-cell" style={{ width: width }}>
            {value}
        </div>
    )
};

export default LightTableRowCell;