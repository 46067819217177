import { useEffect, useRef, useState } from "react";
import loadingAnim from '../../data/json/loading/loading.json'
import Lottie from "react-lottie-player";
import TableHeader from "./table-header/table-header";
import TableRowTotal from "./table-row-total/table-row-total";
import TableRow from "./table-row/table-row";
import "./table.css";


interface FormLineType {
  showListItems: any;
  setListItems: any;
  headers: any;
  options: any;
  onCheckbox?: boolean;
  onDoubleClick?: any;
  fieldsDropMenu?: any;
  showCheckIdList?: any;
  setCheckIdList?: any;
  showTargetTableScroll?: boolean;
  setTargetTableScroll?: any;
  showLoading?: boolean;
}

const Table: React.FC<FormLineType> = ({
  showListItems, setListItems, headers, options, onCheckbox = false, onDoubleClick = false, fieldsDropMenu, showCheckIdList, setCheckIdList, showTargetTableScroll = false, setTargetTableScroll, showLoading = false
}) => {

  let refs = useRef(null);

  const [showAllCheck, setAllCheck] = useState(false);

  const sorter = (itemA: any, itemB: any, order: boolean) => {

    itemA = typeof itemA === "object" ? (itemA.props.className !== undefined ? itemA.props.className : itemA.props.children) : itemA;
    itemB = typeof itemB === "object" ? (itemB.props.className !== undefined ? itemB.props.className : itemB.props.children) : itemB;

    if (itemA < itemB) return order ? -1 : 1;
    else if (itemA > itemB) return order ? 1 : -1;
    return 0;
  };

  const sorting = (key: any, order: boolean) => {
    setListItems([...showListItems].sort((a: any, b: any): any => sorter(a[key], b[key], order)));
  };

  const onChangeCheck = (id: number, check: boolean) => {
    if (!check)
      setCheckIdList([...showCheckIdList, id])
    else
      setCheckIdList(showCheckIdList.filter((CheckId: number) => CheckId !== id))
  }

  function sum(obj: any) {
    var sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }

  const widthOnCheckbox: number = onCheckbox ? 74 : 0;
  const widthOnFuction: number = fieldsDropMenu ? 74 : 0;

  const maxWidth = sum(options) + widthOnCheckbox + widthOnFuction + 15;

  const validAllCheck: boolean = showCheckIdList ? showListItems.filter((item: any) => !item.disabled).length === showCheckIdList.length && showCheckIdList.length > 0 : false;

  useEffect(() => {
    if (showTargetTableScroll) {
      var re: any = refs.current;
      re.children[showListItems.length - 1].scrollIntoView();
      setTargetTableScroll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showListItems]);


  useEffect(() => {
    if (showAllCheck)
      setCheckIdList(showListItems.filter((item: any) => !item.disabled).map((item: any) => item.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllCheck]);


  return (
    <div className="table" style={{ maxWidth: maxWidth }}>
      <TableHeader
        sorting={sorting}
        headers={headers} options={options}
        onCheckbox={onCheckbox} onFuction={fieldsDropMenu}
        showAllCheck={showAllCheck} setAllCheck={setAllCheck}
        validAllCheck={validAllCheck} setCheckIdList={setCheckIdList}
        disabledAllCheck={!showListItems.length}
      />
      <div className="table-rows" ref={refs}>
        {showListItems.map((document: any, index: number) =>
          <TableRow
            key={index} index={index}
            document={document} options={options}
            fieldsDropMenu={fieldsDropMenu}
            onCheckbox={onCheckbox} onDoubleClick={onDoubleClick}
            check={onCheckbox && showCheckIdList.includes(document.id)}
            onChangeCheck={onChangeCheck} disabledRow={document.disabled}
          />
        )}
      </div>
      {onCheckbox && <TableRowTotal showListItems={showListItems} showCheckIdList={showCheckIdList} options={options} headers={headers} />}

      {showLoading && <div className="player-wrapper">
        <Lottie loop play
          animationData={loadingAnim}
          className="table-loader"
        />
      </div>}

    </div>
  )
};

export default Table;