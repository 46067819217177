interface FormLineType {
    index: number;
    id: number;
    onCheck: boolean;
    disabled?: boolean;
    onChange?: any;
    height?: number;
  }
  
  const Checkbox: React.FC<FormLineType> = ({
    index, id, onCheck, disabled, onChange, height
  }) => {
    return (
        <input
          id={index.toString()}
          checked={onCheck}
          tabIndex={id}
          className="checkbox"
          type="checkbox"
          disabled={disabled}
          onChange={() => onChange(!onCheck)}
          style={{height: height}}
          readOnly
        />
    )
  }
  
  
  export default Checkbox;