import "./button.css"

interface FormLineType {
    text: string;
    classStyle?: string;
    onClick?: any;
    disabled?: boolean;
}

const Button: React.FC<FormLineType> = ({ text, classStyle, onClick, disabled=false }) => {

    const buttonStyle: string = classStyle ? "button-" + classStyle : '';
    const buttonTextStyle: string = classStyle ? "button-text-"  + classStyle : '';
    return (
        <button className={['button', buttonStyle].join(' ')} onClick={onClick} disabled={disabled}>
            <div className={['button-text', buttonTextStyle].join(' ')}>{text}</div>
        </button>
    )
}

export default Button;