import { useEffect, useState } from "react";
import "./tab.css";

interface FormLineType {
    title: string;
    showTab: number;
    setTab: any;
    id: number;
    disabled: boolean;
    noLine?: boolean;
}

const Tab: React.FC<FormLineType> = ({
    title, setTab, showTab, id, disabled, noLine
}) => {

    const tabSelected: string = (id === showTab && !disabled) ? noLine ? "tab-selected-noline" : "tab-selected" : '';

    useEffect(() => {
        localStorage.setItem('tab', showTab.toString());
    }, [showTab])

    return (
        <div className={['tab', tabSelected].join(' ')} onClick={() => setTab(id)} >
            {title}
        </div>
    )
};

export default Tab;